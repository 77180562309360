// Basics tags
code {
    padding: 8px;
}

.show-lg {
    display: none !important;
}

@media (min-width: 992px) and (max-width: 1650px) {
    .hide-xl-lg {
        display: none !important;
    }
}

@media (max-width: 1366px) {
    .hide-lap {
        display: none;
    }
    .inner-container {
        padding: 1rem;
    }
    .force-ham-icon {
        .material-icons.mat-icon {
            padding-left: 1rem;
        }
    }
}

@media (min-width: 992px) and (max-width: 1550px) {
    .xl-12 {
        -moz-box-flex: 0 !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .xl-6 {
        -moz-box-flex: 0 !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    body .show-lg {
        display: block !important;
    }
}

@media (min-width: 991px) and (max-width: 1280px) {
    .md-lg-full {
        -moz-box-flex: 0 !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .md-lg-half {
        -moz-box-flex: 0 !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .force-wheather-widget {
        padding-bottom: 12px;
    }
}

pre {
    padding: 15px;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

.label {
    color: #ffffff;
    display: inline-block;
    padding: 2px 14px;
}

.box-shadow-none {
    box-shadow: none !important;
}

.sq-50 {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
    text-align: center !important;
    border-radius: 100% !important;
    font-size: 1.4rem !important;
}

// force Mat Card style
body {
    .mat-card {
        background-color: $md-card-bg-color;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-radius: $border-radius-base;
        padding: $md-card-padding-top-bottom $md-card-padding-left-right;
        overflow: hidden;
        box-shadow: none !important;
        color: $md-card-text-color;
        border: $md-card-border;
        &:hover {
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.1) !important;
        }
    }
    .mat-raised-button.mat-primary,
    .mat-fab.mat-primary,
    .mat-mini-fab.mat-primary {
        background-color: $brand-primary;
    }
    .mat-sidenav-container {
        color: $md-card-text-color;
    }
}

.force-card-title {
    background: $md-card-title-bg;
    margin-top: -$md-card-padding-top-bottom !important;
    margin-left: -$md-card-padding-left-right;
    margin-right: -$md-card-padding-left-right;
    padding-left: $md-card-padding-left-right;
    padding-right: $md-card-padding-left-right;
    padding-top: $md-card-padding-top-bottom;
    h4 {
        font-size: $md-card-title-font-size;
        color: $md-card-title-font-color;
    }
    .mat-divider {
        margin-left: -$md-card-padding-left-right;
        margin-right: -$md-card-padding-left-right;
        margin-top: 0.5rem;
        border-color: $md-card-separator-color;
    }
    .material-icons {
        font-size: $md-card-icon-size;
    }
}

.force-card-inner,
.flex-align,
.flex-align-top {
    display: flex;
    .force-start {
        flex: 1 1 auto;
    }
}

.force-card-inner,
.flex-align {
    align-items: center;
}

.flex-align-top {
    align-items: flex-start;
}

.force-card-footer,
.force-card-title {
    .mat-icon-button {
        height: 2.188rem;
        line-height: 1.1;
        width: 2.188rem;
        margin: 0;
        color: #868e96;
        font-size: 0.875rem;
    }
}

.force-card-content-sm {
    padding: 0.5rem 0 0;
}

.force-card-content {
    padding: 1.3rem 0 1rem 0;
}

.force-card-footer {
    .mat-divider {
        padding: 0.3em;
    }
}

.no-gutter {
    margin-left: -$md-card-padding-left-right;
    margin-right: -$md-card-padding-left-right;
}

//Radius classes
.radius-none {
    border-radius: 0;
}

.radius-circle {
    border-radius: 50%;
}

.border-none {
    border: 0 !important;
}

.border-rad-base {
    border-radius: $border-radius-base;
}

//Overflow hidden
.overflow-visible {
    overflow: visible;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

// Position
.force-relative {
    position: relative;
}

.force-fixed {
    position: fixed;
}

.force-absolute {
    position: absolute;
}

// List
.list-inline {
    li {
        display: inline-block;
    }
}

.list-style-none {
    list-style: none;
}

.force-list {
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    >li {
        margin-bottom: 1rem;
    }
}

i {
    margin-right: 0.5rem;
}

.list-style-none {
    list-style: none;
    padding-left: 0;
}

// Helper classes
.box-inset {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) inset;
}

.full-wid {
    width: 100%;
}

.force-span {
    display: inline-block;
    vertical-align: top;
}

.force-block {
    display: block;
}

.button-block {
    display: block;
    width: 100%;
}

.inline-block {
    display: inline-block;
}

.img-circle {
    border-radius: 50%;
}

.shadow-none {
    box-shadow: none !important;
}

.background-none {
    background-color: transparent !important;
}

.pad-wrap {
    padding-left: 1rem;
    padding-right: 1rem;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.width-100 {
    width: 100px;
}

[dir="rtl"] .alternate-bg-list {
    .ml-auto {
        margin-right: auto;
        margin-left: inherit;
    }
}

.force-mail-wrapper .navigation .mat-list-item-content {
    padding: 0 !important;
}

//Table Pagination
@media (max-width: 767px) {
    body {
        .ngx-datatable.material .datatable-footer .datatable-pager a {
            margin: 12px 1px;
            min-width: 17px;
            padding: 0 2px;
            text-align: center;
        }
        .ngx-datatable .datatable-footer>div {
            display: block;
            width: 100%;
        }
        .ngx-datatable .datatable-footer .page-count {
            display: none;
        }
        .ngx-datatable .datatable-footer .datatable-pager {
            -moz-box-flex: 1;
            display: inline-block;
            -moz-box-flex: 0;
            flex: 0 0 100%;
            text-align: right;
            width: 98%;
        }
    }
}

//TO override Material
body {
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-radio-checked .mat-radio-outer-circle,
    .mat-toolbar.mat-primary {
        @extend .bg-primary;
    }
    .mat-fab.mat-accent,
    .mat-mini-fab.mat-accent,
    .mat-raised-button.mat-accent,
    .mat-toolbar.mat-accent {
        @extend .bg-success;
    }
    .mat-fab.mat-warn,
    .mat-mini-fab.mat-warn,
    .mat-raised-button.mat-warn {
        @extend .bg-danger;
    }
    .mat-fab,
    .mat-mini-fab {
        @extend .bg-success;
    }
}

.program-icon {
    //font-size: 20px !important;
    padding: 0px !important;
    margin-top: -5px;
}

.program-icon-gray {
    color: #c3c3c3;
}

.program-icon-red {
    color: #da0303;
}

.program-icon-red:hover {
    color: #da0303;
}

.program-icon-green {
    color: #399805;
}

.program-icon-green:hover {
    color: #399805;
}

.program-icon-blue {
    color: #0363da;
}

.program-icon-blue:hover {
    color: #0363da;
}

.program-icon-blue-wow {
    color: #146d98;
}

.program-icon-blue-wow:hover {
    color: #146d98;
}

.text-success {
    color: #5cb85c;
}

.text-warning {
    color: #f0ad4e;
}

.text-default {
    color: #777;
}

.text-info {
    color: #5bc0de;
}

.text-danger {
    color: #d9534f;
}

.search-content {
    label {
        display: block;
        margin-bottom: 3px;
        font-size: 16px;
        color: #5c5c5d;
        text-align: left;
        font-weight: bold;
        padding-left: 0;
        width: inherit;
    }
}

.btn-zoom {
    background-color: #fff;
    text-decoration: underline;
    margin-top: 10px;
    color: #5c5c5d;
}

.btn-search {
    border-radius: 6px;
    text-align: center;
    font-size: 18px;
}

.btn-reset {
    background: transparent;
    border: solid 1px transparent;
    color: #5c5c5d;
    text-decoration: underline;
    line-height: 42px;
    border-radius: 6px;
    text-align: center;
    font-size: 18px;
}

.search-pagination {
    .pagination {
        .numbers {
            button.btn {
                color: #155169 !important;
                border: solid 1px #155169 !important;
                border-radius: 5px !important;
                background-color: #e7e7e8 !important;
            }
            button.btn:hover {
                border: solid 1px #155169 !important;
                background-color: #155169 !important;
                color: #fff !important;
                text-decoration: none !important;
            }
        }
    }
}