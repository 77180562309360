//media
.force-grid-item {
    position: relative;
    .force-grid-hover {
        position: absolute;
        opacity: 0;
        transition: all ease 500ms;
        background: rgba(0, 0, 0, 0.7);
        text-align: left;
        padding: 2rem 0.5rem;
    }
    &:hover {
        .force-grid-hover {
            opacity: 1;
        }
    }
    .mat-grid-tile-footer {
        bottom: 0;
        height: auto !important;
        left: 0 !important;
        right: 0 !important;
        text-align: center;
        justify-content: center;
        h2 {
            font-size: 1rem !important;
            color: #fff;
        }
    }
}

//pricing
.pricing-wrapper {
    .mat-list-item-content {
        justify-content: center;
    }
    button {
        margin-top: 1.5rem;
    }
    .mat-card {
        transition: all 0.5s ease-in-out 0s;
        &:hover {
            transform: scale(1.05);
        }
    }
}

//user list
.user-list-wrap .mat-icon {
    vertical-align: top;
    margin-right: 0.5rem;
}

.user-thumb {
    width: 70px;
}

.user-name {
    font-size: 1.125rem;
}

.force-btn-sm {
    border-radius: 3px;
    padding: 3px 15px;
}

.user-list-wrapper {
    .mat-card {
        border-radius: 0;
    }
}


//Room List Print Modal
@media(min-width:736px) {
    .modal-print {
        width: 95%;
    }
    .modal-print, 
    .modal-print .modal-content {
        max-width: none;
        height: 96%;
    }
}

.modal-full-width {
    width: 95%;
    max-width: none;
    .modal-content {
        max-width: none;
    }
}

.modal-fit-height {
    height: auto;
    .modal-content {
        height: auto;
    }
}

