//form upload
.file-type {
    background-color: $file-type-area-bg;
    border: 3px dotted $file-type-dotted-area;
    display: block;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: $border-radius;
}

.force-file-upload {
    button {
        mat-button-ripple mat-ripple {
            opacity: 0.7 !important;
            cursor: not-allowed !important;
        }
    }
    [ng-reflect-trigger="[object HTMLButtonElement]"] {
        opacity: 1 !important;
        cursor: pointer !important;
    }
}

.app-dark {
    .file-type {
        background-color: transparent;
    }
}

//form wizard
.force-form-wizard {
    .mat-tab-header {
        background-color: $form-wiz-tab-bg-color;
        margin-bottom: 3rem;
    }
    .mat-tab-label-container .mat-tab-label-active {
        background-color: $active-tab-bg;
        opacity: 1;
        border: none;
    }
    .mat-tab-nav-bar,
    .mat-tab-header {
        border: none;
    }
    .mat-input-container {
        margin-bottom: 2rem;
    }
    .mat-button {
        border: $btn-border;
        margin: 0.3rem;
    }
    .custom-pad {
        padding-bottom: 4.4rem;
        padding-top: 4.4rem;
    }
    .mat-tab-body-content {
        overflow: hidden;
    }
}

body .force-form-wizard .mat-input-container {
    font-size: $form-wiz-font-size;
}

.force-form-wizard .mat-raised-button,
.mat-fab,
.mat-mini-fab,
.mat-tab-label-container .mat-tab-label-active {
    background-color: $btn-bg-color;
    color: $btn-color;
}

.app-dark .force-form-wizard .mat-tab-header {
    background-color: transparent;
}
