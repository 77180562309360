$editor-bg:#fff;
$editor-header-bg:#fff;
.ql-container {
    background-color: $editor-bg;
}

.ql-toolbar.ql-snow {
    padding: 15px;
    background-color: $editor-header-bg;
}

@media(min-width:1280px) {
    body .ql-container .ql-editor {
        min-height: 400px;
    }
}