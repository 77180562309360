//To set bg classes text color to white 
.bg-primary{
    color: #fff !important;
}
.bg-success{
     color: #fff !important;
}
.bg-info{
     color: #fff !important;
}
.bg-warning{
     color: #fff !important;
}
.bg-danger{
     color: #fff !important;
}
.bg-inverse{
     color: #fff !important;
}
