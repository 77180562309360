
.twp-icon {
    @include twp-icon-base;

    &-light {
        @include twp-icon-promotion;
    }
    
    &-clock {
        @include twp-icon-promotion;
        height: 17px;
        background-position-y: -35px;
    }

    &-fire {
        @include twp-icon-promotion;
        height: 17px;
        background-position-y: 65px;
    }

    &-arrow-left {
        @include twp-icon-promotion;
        height: 17px;
        background-position-y: 35px;
    }    
}

.icon-printer{
    display: inline-flex;
    position: relative;
    top: 3px;
    width: 18px;
    height: 18px;
    background-image: url('/assets/img/icon-printer.svg');
}

.icon-preview-invoice{
    display: inline-flex;
    position: relative;
    top: 6px;
    width: 18px;
    height: 20px;
    background-image: url('/assets/img/icon-preview-invoice.svg');
}