/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css?family=Prompt:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Prompt:300,400,500,700");

@font-face {
  font-family: 'Prompt', sans-serif;
}


/* before demo1.1 */

.sidebar-area.mat-sidenav {
    background-color: transparent;
}

.sidebar-area.mat-sidenav,
.sidebar-logo.mat-toolbar,
.force-user-thumb,
.mat-list-item:hover {
  background-color: #1565c0;
}

.active-link {
  background-color: #146D98 !important;
}


/* generic css */

.success-message {
  color: #3CCD6F;
}

.error-message {
  color: red;
}

.required::after {
  color: red;
  content: ' *';
}

.cdk-overlay-container {
  z-index: 1100 !important;
}

.mat-calendar-content[ng-reflect-ng-switch=false] .mat-calendar-body-label {
  visibility: hidden;
}

.text-wrap {
  word-wrap: break-word;
}

.navbar-brand {
  color: #FFF;
}


/* end: before demo1.1 */


/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}


/* Gutter */


/* Breakpoints */


/* COLOR */

.col.center {
  text-align: center;
}

.span-1 {
  width: 8.33333%;
}

.span-2 {
  width: 16.66667%;
}

.span-3 {
  width: 25%;
}

.span-4 {
  width: 33.33333%;
}

.span-5 {
  width: 41.66667%;
}

.span-6 {
  width: 50%;
}

.span-7 {
  width: 58.33333%;
}

.span-8 {
  width: 66.66667%;
}

.span-9 {
  width: 75%;
}

.span-10 {
  width: 83.33333%;
}

.span-11 {
  width: 91.66667%;
}

.span-12 {
  width: 100%;
}

@media screen and (min-width: 480px) {
  .xs-1 {
    width: 8.33333%;
  }

  .xs-2 {
    width: 16.66667%;
  }

  .xs-3 {
    width: 25%;
  }

  .xs-4 {
    width: 33.33333%;
  }

  .xs-5 {
    width: 41.66667%;
  }

  .xs-6 {
    width: 50%;
  }

  .xs-7 {
    width: 58.33333%;
  }

  .xs-8 {
    width: 66.66667%;
  }

  .xs-9 {
    width: 75%;
  }

  .xs-10 {
    width: 83.33333%;
  }

  .xs-11 {
    width: 91.66667%;
  }

  .xs-12 {
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .sm-1 {
    width: 8.33333%;
  }

  .sm-2 {
    width: 16.66667%;
  }

  .sm-3 {
    width: 25%;
  }

  .sm-4 {
    width: 33.33333%;
  }

  .sm-5 {
    width: 41.66667%;
  }

  .sm-6 {
    width: 50%;
  }

  .sm-7 {
    width: 58.33333%;
  }

  .sm-8 {
    width: 66.66667%;
  }

  .sm-9 {
    width: 75%;
  }

  .sm-10 {
    width: 83.33333%;
  }

  .sm-11 {
    width: 91.66667%;
  }

  .sm-12 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .md-1 {
    width: 8.33333%;
  }

  .md-2 {
    width: 16.66667%;
  }

  .md-3 {
    width: 25%;
  }

  .md-4 {
    width: 33.33333%;
  }

  .md-5 {
    width: 41.66667%;
  }

  .md-6 {
    width: 50%;
  }

  .md-7 {
    width: 58.33333%;
  }

  .md-8 {
    width: 66.66667%;
  }

  .md-9 {
    width: 75%;
  }

  .md-10 {
    width: 83.33333%;
  }

  .md-11 {
    width: 91.66667%;
  }

  .md-12 {
    width: 100%;
  }
}

@media screen and (min-width: 980px) {
  .lg-1 {
    width: 8.33333%;
  }

  .lg-2 {
    width: 16.66667%;
  }

  .lg-3 {
    width: 25%;
  }

  .lg-4 {
    width: 33.33333%;
  }

  .lg-5 {
    width: 41.66667%;
  }

  .lg-6 {
    width: 50%;
  }

  .lg-7 {
    width: 58.33333%;
  }

  .lg-8 {
    width: 66.66667%;
  }

  .lg-9 {
    width: 75%;
  }

  .lg-10 {
    width: 83.33333%;
  }

  .lg-11 {
    width: 91.66667%;
  }

  .lg-12 {
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .xl-1 {
    width: 8.33333%;
  }

  .xl-2 {
    width: 16.66667%;
  }

  .xl-3 {
    width: 25%;
  }

  .xl-4 {
    width: 33.33333%;
  }

  .xl-5 {
    width: 41.66667%;
  }

  .xl-6 {
    width: 50%;
  }

  .xl-7 {
    width: 58.33333%;
  }

  .xl-8 {
    width: 66.66667%;
  }

  .xl-9 {
    width: 75%;
  }

  .xl-10 {
    width: 83.33333%;
  }

  .xl-11 {
    width: 91.66667%;
  }

  .xl-12 {
    width: 100%;
  }
}

body {
  font-size: 1rem;
  font-family: "Helvetica", tahoma, sans-serif !important;
  line-height: 1.4;
  color: #5c5c5d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

input,
select,
textarea {
  font-family: tahoma, Helvetica;
}

a {
  color: #222222;
  text-decoration: none;
}

*:focus {
  outline: none;
}

strong {
  font-weight: 700;
}

button {
  cursor: pointer;
}

.imgblock {
  width: 100%;
  display: block;
  background-color: #949292;
}

.textreplacement {
  display: block;
  display: inline-block;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.col {
  float: left;
  flex: none;
  padding-left: 15px;
  padding-right: 15px;
}


/* IE8
   ========================================================================== */

.lt-ie9 .remodal-overlay {
  background: #2b2e38;
}

.lt-ie9 .remodal {
  width: 700px;
}

.title {
  color: #146d99;
  text-align: left;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
  display: inline-block;
  /* font-size: 2.4rem; */
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 24px;
    /* font-size: 3rem; */
  }
}

.title img {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  top: 1px;
  width: 25px;
}

.title--main {
  font-size: 24px;
  font-weight: bold;
  color: #155169;
}

.title--secondary {
  font-size: 18px;
  font-weight: bold;
  color: #155169;
}

.title--second {
  color: #155169;
  font-size: 18px;
  /* font-size: 1.8rem; */
  text-decoration: underline;
}

.title--gray {
  color: #5c5c5d !important;
  text-decoration: none !important;
  margin-bottom: 5px !important;
}

.title--small {
  font-size: .7em;
  font-weight: normal;
  color: #757576;
}

@media screen and (min-width: 768px) {
  .title--second {
    font-size: 18px;
    /* font-size: 2.1rem; */
    margin-bottom: 30px;
  }
}

.main-content .label {
  display: block;
  margin-bottom: 3px;
  font-size: 16px;
  color: #5c5c5d;
  /* font-size: 1.6rem; */
  text-align: left;
  font-weight: bold;
  padding-left: 0;
}

.main-content ol,
.main-content ul,
.main-content dl {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .label {
    font-size: 20px;
    /* font-size: 2rem; */
  }
}

.label i {
  color: #fc0315;
  font-size: 1.5em;
  line-height: 0;
  position: relative;
  top: 7px;
}

.input,
.textarea,
.select {
  display: block;
  margin-bottom: 5px;
  height: 28px;
  width: 100%;
  background: #fff;
  border: solid 1px #c1c1c2;
  border-radius: 5px;
  color: #a7a7a8;
  padding: 0 15px;
  /* font-size: 16px; */
  /* font-size: 1.6rem; */
  position: relative;
}

.input__datepicker {
  width: 1px;
  height: 1px;
  position: absolute;
}

.icon__datepicker {
  position: absolute;
  right: 20px;
  bottom: 32px;
}

.datepicker {
  position: relative;
  width: 200px;
}

.datepicker input.hidden {
  visibility: hidden;
  width: 2px;
  height: 2px;
  position: absolute;
}

.datepicker input {
  margin: 0;
  width: 200px;
  height: 30px;
  color: #7a7a7b;
}

.datepicker i {
  position: absolute;
  bottom: 8px;
  right: 0;
}

.datepicker .form-control~i {
  bottom: 30%;
}


@media screen and (max-width: 767px) {

  .input,
  .textarea,
  .select {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {

  .input,
  .textarea,
  .select {
    /* font-size: 18px; */
    /* font-size: 1.8rem; */
  }
}

.input:focus,
.textarea:focus,
.select:focus {
  border: solid 1px #5c5c5d;
  color: #5c5c5d;
}

.input.error,
.textarea.error,
.select.error {
  border: solid 1px #fc0315;
}

span.error {
  color: #fc0315;
  position: relative;
}

@media screen and (max-width: 959px) {
  span.error {
    top: -15px;
  }
}

.textarea {
  height: 120px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  vertical-align: top;
  resize: none;
}

@media screen and (min-width: 768px) {
  .form .row {
    margin-bottom: 25px;
  }

  .form .row:last-child {
    margin-bottom: 10px;
  }
}

.form__half--input>div {
  position: relative;
}

.form__half--input .input {
  width: 78%;
}

.form__half--input .label {
  position: absolute;
  top: 15px;
  left: 80%;
}

@media screen and (max-width: 767px) {
  .form__half--check {
    margin-bottom: 20px;
  }
}

.form__half--check .label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  cursor: pointer;
}

.form__half--check input[type="checkbox"] {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.form__half--check input[type="checkbox"]:checked+span {
  background-color: #146d99;
  border: solid 1px #146d99;
}

.form__half--check input[type="checkbox"]:checked+span:after {
  content: "";
  background: url(./assets/img/icon-check.png) no-repeat;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 5px;
  left: 2px;
}

.form__half--check input[type="checkbox"]+span {
  display: inline-block;
  width: 28px;
  height: 28px;
  border: solid 1px #c1c1c2;
  border-radius: 5px;
  margin-right: 7px;
  position: relative;
  top: 8px;
}

.form__half--check input[type="radio"] {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.form__half--check input[type="radio"]:checked+span {
  background-color: transparent;
  border: solid 1px #c1c1c2;
}

.form__half--check input[type="radio"]:checked+span:after {
  content: "";
  background-color: #146d99;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
}

.form__half--check input[type="radio"]+span {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: solid 1px #c1c1c2;
  border-radius: 50%;
  margin-right: 7px;
  position: relative;
  top: 7px;
}

.download-programtour:before,
.download-programtour:after {
  content: " ";
  display: table;
}

.download-programtour:after {
  clear: both;
}

.selected-list .c-btn .c-token {
  margin-top: 0px !important;
  padding: 2px 25px 2px 5px !important;
  font-size: 13px !important;
}

.selected-list .c-btn {
  box-shadow: 0px 1px 5px transparent !important;
  padding: 0 15px !important;
  border: solid 1px #c1c1c2 !important;
  border-radius: 5px !important;
  color: #a7a7a8 !important;
  font-family: "Poppins", sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
  font-size: 100% !important;
  height: 28px;
}

@media screen and (max-width: 767px) {
  .download-programtour li {
    margin-bottom: 15px;
    padding: 0;
  }
}

.download-programtour li .box {
  display: block;
  border: dashed 2px #cecece;
  text-align: center;
  padding: 15px 0;
}

.download-programtour li img {
  display: block;
  margin: 0 auto 10px;
}

.download-programtour li .span {
  display: inline-block;
  max-width: 160px;
  width: 100%;
  background: #146d99;
  color: #fff;
  border-radius: 2px;
  padding: 5px 0;
}

.main-content .btn {
  background: #e7e7e8;
  border: solid 1px #146d99;
  color: #146d99;
  border-radius: 6px;
  text-align: center;
  display: inline-block;
  width: 100%;
  height: 56px;
  line-height: 40px;
  font-size: 18px;
  /* font-size: 1.8rem; */
}

@media screen and (max-width: 767px) {
  .main-content .btn {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .main-content .btn {
    /* font-size: 24px; */
    /* font-size: 2.4rem; */
    width: auto;
    padding: 0 1em;
    margin: 0 5px;
    /* height: 62px; */
    line-height: 42px;
  }
}

@media screen and (min-width: 960px) {
  .main-content .btn {
    padding: 0 2em;
    min-width: 170px;
  }
}

@media screen and (max-width: 767px) {
  .main-content .btn--half {
    width: 49%;
  }
}

.section {
  padding: 20px 15px;
}

.section--white {
  background: #fff;
}

.section--gray {
  background: #edefef;
}

.section--btn {
  text-align: center;
}

.section--highlight {
  background-color: #d9edf2;
}

@media screen and (min-width: 768px) {
  .section--highlight .title--third {
    margin-bottom: 30px;
  }
}

.main-content {
  background: #f5f5f5;
  padding: 20px 15px;
}

@media screen and (min-width: 960px) {
  .main-content {
    padding: 30px;
  }
}


/* demo1.1 - Order - Add */

.add-order__box {
  border: solid 1px #b4b4b5;
  margin-bottom: 30px;
}

.add-order__box .title--second {
  padding: 20px 15px;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .add-order__box .title--second {
    padding: 20px 30px;
  }
}

.add-order__box:nth-child(2n) {
  margin-bottom: 120px;
}

@media screen and (min-width: 768px) {
  .add-order__box:nth-child(2n) {
    margin-bottom: 90px;
  }
}

.add-order__formsecond {
  position: relative;
}

.add-order__formsecond--time {
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 330px) {
  .add-order__formsecond--time {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .add-order__formsecond--time {
    font-size: 22px;
  }
}

.add-order__formsecond--time span,
.add-order__formsecond--time strong {
  display: inline-block;
  height: 45px;
  line-height: 45px;
  vertical-align: top;
}

@media screen and (min-width: 640px) {

  .add-order__formsecond--time span,
  .add-order__formsecond--time strong {
    width: auto;
    text-align: left;
  }
}

.add-order__formsecond--time span {
  background: #d9edf2;
  color: #222222;
  padding: 0 10px 0 15px;
}

@media screen and (max-width: 639px) {
  .add-order__formsecond--time span {
    width: 65%;
    padding-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .add-order__formsecond--time span {
    padding: 0 20px 0 33px;
  }
}

.add-order__formsecond--time strong {
  font-size: 1.2em;
  padding: 0 25px;
  background: #459e75;
  color: #fff;
}

@media screen and (max-width: 639px) {
  .add-order__formsecond--time strong {
    width: 35%;
    text-align: center;
    padding: 0;
  }
}

.add-order__formsecond--list {
  padding: 20px 15px;
}

.add-order__formsecond--list:before,
.add-order__formsecond--list:after {
  content: " ";
  display: table;
}

.add-order__formsecond--list:after {
  clear: both;
}

.add-order__formsecond--list li {
  font-size: 14px;
  color: #222222;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .add-order__formsecond--list li {
    font-size: 18px;
  }
}

.add-order__formsecond--list li strong,
.add-order__formsecond--list li span {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  font-size: 89%;
}

.add-order__formsecond--list li strong {
  color: #146d99;
}

.add-order__formsecond--list li .input {
  border-radius: 6px;
  color: #222222;
  font-weight: 700;
  text-align: center;
  font-size: 2.2;
  height: 60px;
  margin: 5px auto;
}

@media screen and (min-width: 768px) {
  .add-order__formsecond--list li .input {
    font-size: 30px;
    width: 80%;
  }
}

.add-order__formsecond--total {
  position: absolute;
}

.add-order__formsecond--total strong,
.add-order__formsecond--total span {
  display: inline-block;
  height: 60px;
  line-height: 55px;
  font-size: 18px;
  font-weight: 700;
}

.add-order__formsecond--total strong {
  background: #d9edf2;
  margin-top: 1px;
  color: #222222;
}

@media screen and (max-width: 767px) {
  .add-order__formsecond--total strong {
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .add-order__formsecond--total strong {
    padding: 0 40px;
  }
}

.add-order__formsecond--total span {
  font-size: 16px;
  position: relative;
  top: -1px;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .add-order__formsecond--total span {
    height: auto;
    line-height: 1.5;
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) {
  .add-order__formsecond--total span {
    padding: 0 10px;
  }
}

.add-order__formsecond--total b {
  font-size: 2em;
}

.add-order__expand {
  clear: left;
  margin-bottom: 20px;
}

.add-order__expand .title--second {
  color: #222222;
  padding-left: 15px;
}

@media screen and (max-width: 767px) {
  .add-order__expand .title--second {
    padding-bottom: 10px;
  }
}

.title--third {
  font-size: 18px;
  color: #222222;
}

@media screen and (min-width: 768px) {
  .title--third {
    font-size: 22px;
  }
}

.add-order__expand--wrap:before,
.add-order__expand--wrap:after {
  content: " ";
  display: table;
}

.add-order__expand--wrap:after {
  clear: both;
}

.add-order__expand--wrap span,
.add-order__expand--wrap strong {
  display: inline-block;
  font-size: 16px;
  color: #222222;
  font-weight: 700;
}

@media screen and (min-width: 768px) {

  .add-order__expand--wrap span,
  .add-order__expand--wrap strong {
    font-size: 20px;
  }
}

.add-order__expand--wrap span {
  margin-right: 10px;
}

.add-order__more--top .textarea {
  height: 150px;
}

.add-order__more--second {
  margin-top: 20px;
}

.add-order__more--second:before,
.add-order__more--second:after {
  content: " ";
  display: table;
}

.add-order__more--second:after {
  clear: both;
}

@media screen and (min-width: 768px) {

  .add-order__more--second .label,
  .add-order__more--second .select {
    float: left;
  }

  .add-order__more--second .label {
    width: 185px;
    position: relative;
    top: 11px;
  }

  .add-order__more--second .select {
    width: 40%;
  }
}

.add-order__highlight--top {
  position: relative;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .add-order__highlight--top {
    margin-bottom: 45px;
  }
}

.add-order__highlight--top span {
  font-size: 12px;
  position: relative;
  top: 2px;
}

.add-order__highlight--top .add-order__highlight--zoom {
  /*
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 0 15px;
  text-align: center;
  */
}

.add-order__highlight--top .add-order__highlight--zoom a {
  display: inline-block;
  background: #fff;
  width: 100%;
  padding: 5px 0;
  position: relative;
  color: #5c5c5d;
  text-decoration: underline;
  font-size: 12px;
}

.add-order__highlight--top .add-order__highlight--zoom a:before {
  content: "";
  background: url(/assets/img/icon-zoom.png) no-repeat;
  width: 20px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 4px;
}

@media screen and (min-width: 768px) {
  .add-order__highlight--second {
    padding-top: 20px;
    padding-left: 40px !important;
  }

  .add-order__highlight--second>.row {
    margin-left: 0;
    margin-right: 0;
  }
}

ul.add-order__highlight--list {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #fff;
}

.add-order__highlight--list:before,
.add-order__highlight--list:after {
  content: " ";
  display: table;
}

.add-order__highlight--list:after {
  clear: both;
}

.add-order__highlight--list li {
  float: left;
}

ul.add-order__highlight--list:last-child {
  border: none;
}

@media screen and (max-width: 340px) {
  .add-order__highlight--list li {
    font-size: 13px;
  }
}

.add-order__highlight--list li span,
.add-order__highlight--list li strong {
  display: block;
}

.add-order__highlight--list li span {
  margin-bottom: 5px;
}

.add-order__highlight--list li strong {
  color: #222222;
  font-size: 14px;
}

@media screen and (min-width: 640px) {
  .add-order__highlight--list li strong {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .add-order__highlight--list li strong {
    font-size: 20px;
  }
}

.add-order__highlight--list li img {
  margin-right: 10px;
}

.add-order__highlight--list li .flag {
  border: solid 1px #c1c1c2;
}

@media screen and (max-width: 340px) {
  .add-order__highlight--list li .flag {
    max-width: 55px;
  }
}


/* ================  */

body {
  /* background-color: #165068 !important; */
}

.mat-sidenav-content {
  margin-left: 256px;
  margin-right: 0px;
  transform: translate3d(0px, 0px, 0px);
  background-color: #f6f8fc;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: block;
}

@media screen and (max-width: 959px) {
  .mat-sidenav-content {
    margin-left: 0;
  }
}


/* http://meyerweb.com/eric/tools/css/reset/
 v2.0 | 20110126
 License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}


/* Gutter */


/* Breakpoints */


/* COLOR */

.col.center {
  text-align: center;
}

.span-1 {
  width: 8.33333%;
}

.span-2 {
  width: 16.66667%;
}

.span-3 {
  width: 25%;
}

.span-4 {
  width: 33.33333%;
}

.span-5 {
  width: 41.66667%;
}

.span-6 {
  width: 50%;
}

.span-7 {
  width: 58.33333%;
}

.span-8 {
  width: 66.66667%;
}

.span-9 {
  width: 75%;
}

.span-10 {
  width: 83.33333%;
}

.span-11 {
  width: 91.66667%;
}

.span-12 {
  width: 100%;
}

@media screen and (min-width: 480px) {
  .xs-1 {
    width: 8.33333%;
  }

  .xs-2 {
    width: 16.66667%;
  }

  .xs-3 {
    width: 25%;
  }

  .xs-4 {
    width: 33.33333%;
  }

  .xs-5 {
    width: 41.66667%;
  }

  .xs-6 {
    width: 50%;
  }

  .xs-7 {
    width: 58.33333%;
  }

  .xs-8 {
    width: 66.66667%;
  }

  .xs-9 {
    width: 75%;
  }

  .xs-10 {
    width: 83.33333%;
  }

  .xs-11 {
    width: 91.66667%;
  }

  .xs-12 {
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .sm-1 {
    width: 8.33333%;
  }

  .sm-2 {
    width: 16.66667%;
  }

  .sm-3 {
    width: 25%;
  }

  .sm-4 {
    width: 33.33333%;
  }

  .sm-5 {
    width: 41.66667%;
  }

  .sm-6 {
    width: 50%;
  }

  .sm-7 {
    width: 58.33333%;
  }

  .sm-8 {
    width: 66.66667%;
  }

  .sm-9 {
    width: 75%;
  }

  .sm-10 {
    width: 83.33333%;
  }

  .sm-11 {
    width: 91.66667%;
  }

  .sm-12 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .md-1 {
    width: 8.33333%;
  }

  .md-2 {
    width: 16.66667%;
  }

  .md-3 {
    width: 25%;
  }

  .md-4 {
    width: 33.33333%;
  }

  .md-5 {
    width: 41.66667%;
  }

  .md-6 {
    width: 50%;
  }

  .md-7 {
    width: 58.33333%;
  }

  .md-8 {
    width: 66.66667%;
  }

  .md-9 {
    width: 75%;
  }

  .md-10 {
    width: 83.33333%;
  }

  .md-11 {
    width: 91.66667%;
  }

  .md-12 {
    width: 100%;
  }
}

@media screen and (min-width: 980px) {
  .lg-1 {
    width: 8.33333%;
  }

  .lg-2 {
    width: 16.66667%;
  }

  .lg-3 {
    width: 25%;
  }

  .lg-4 {
    width: 33.33333%;
  }

  .lg-5 {
    width: 41.66667%;
  }

  .lg-6 {
    width: 50%;
  }

  .lg-7 {
    width: 58.33333%;
  }

  .lg-8 {
    width: 66.66667%;
  }

  .lg-9 {
    width: 75%;
  }

  .lg-10 {
    width: 83.33333%;
  }

  .lg-11 {
    width: 91.66667%;
  }

  .lg-12 {
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .xl-1 {
    width: 8.33333%;
  }

  .xl-2 {
    width: 16.66667%;
  }

  .xl-3 {
    width: 25%;
  }

  .xl-4 {
    width: 33.33333%;
  }

  .xl-5 {
    width: 41.66667%;
  }

  .xl-6 {
    width: 50%;
  }

  .xl-7 {
    width: 58.33333%;
  }

  .xl-8 {
    width: 66.66667%;
  }

  .xl-9 {
    width: 75%;
  }

  .xl-10 {
    width: 83.33333%;
  }

  .xl-11 {
    width: 91.66667%;
  }

  .xl-12 {
    width: 100%;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

input,
select,
textarea {
  font-family: tahoma, Helvetica;
}

a {
  color: #222222;
  text-decoration: none;
}

*:focus {
  outline: none;
}

strong {
  font-weight: 700;
}

button {
  cursor: pointer;
}

.imgblock {
  width: 100%;
  display: block;
}

.textreplacement {
  display: block;
  display: inline-block;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.col {
  float: left;
  flex: none;
  padding-left: 15px;
  padding-right: 15px;
}


/* IE8
 ========================================================================== */

.lt-ie9 .remodal-overlay {
  background: #2b2e38;
}

.lt-ie9 .remodal {
  width: 700px;
}

.main-content .label {
  display: block;
  margin-bottom: 3px;
  font-size: 16px;
  color: #5c5c5d;
  /* font-size: 1.6rem; */
  text-align: left;
  font-weight: bold;
  padding-left: 0;
}

.main-content ol,
.main-content ul,
.main-content dl {
  margin: 0;
  padding: 0;
}

.label {
  display: block;
  margin-bottom: 3px;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .label {
    font-size: 20px;
    /* font-size: 2rem; */
  }
}

.label i {
  color: #fc0315;
  font-size: 1.5em;
  line-height: 0;
  position: relative;
  top: 7px;
}

.input::placeholder,
.textarea::placeholder,
.select::placeholder {
  color: #a7a7a8;
}

span.error {
  color: #fc0315;
  position: relative;
}

@media screen and (max-width: 959px) {
  span.error {
    top: -15px;
  }
}

@media screen and (min-width: 768px) {
  .form .row {
    margin-bottom: 25px;
  }

  .form .row:last-child {
    margin-bottom: 10px;
  }
}

.form__half--input>div {
  position: relative;
}

.form__half--input .input {
  width: 78%;
}

.form__half--input .label {
  position: absolute;
  top: 15px;
  left: 80%;
}

@media screen and (max-width: 767px) {
  .form__half--check {
    margin-bottom: 20px;
  }
}

.form__half--check .label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  cursor: pointer;
}

.form__half--check input[type="checkbox"] {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.form__half--check input[type="checkbox"]:checked+span {
  background-color: #146d99;
  border: solid 1px #146d99;
}

.form__half--check input[type="checkbox"]:checked+span:after {
  content: "";
  background: url(/assets/img/icon-check.png) no-repeat;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 5px;
  left: 2px;
}

.form__half--check input[type="checkbox"]+span {
  display: inline-block;
  width: 28px;
  height: 28px;
  border: solid 1px #c1c1c2;
  border-radius: 5px;
  margin-right: 7px;
  position: relative;
  top: 8px;
}

.form__half--check input[type="radio"] {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.form__half--check input[type="radio"]:checked+span {
  background-color: transparent;
  border: solid 1px #c1c1c2;
}

.form__half--check input[type="radio"]:checked+span:after {
  content: "";
  background-color: #146d99;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
}

.form__half--check input[type="radio"]+span {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: solid 1px #c1c1c2;
  border-radius: 50%;
  margin-right: 7px;
  position: relative;
  top: 7px;
}

.download-programtour:before,
.download-programtour:after {
  content: " ";
  display: table;
}

.download-programtour:after {
  clear: both;
}

@media screen and (max-width: 767px) {
  .download-programtour li {
    margin-bottom: 15px;
    padding: 0;
  }
}

.download-programtour li .box {
  display: block;
  border: dashed 2px #cecece;
  text-align: center;
  padding: 15px 0;
}

.download-programtour li img {
  display: block;
  margin: 0 auto 10px;
}

.download-programtour li .span {
  display: inline-block;
  max-width: 160px;
  width: 100%;
  background: #146d99;
  color: #fff;
  border-radius: 2px;
  padding: 5px 0;
}


label.btn,
label.image {
  cursor: pointer;
}

label.btn input[type=file] {
  display: none;
}

label.image input[type=file] {
  display: none;
}

.txt--light-grey {
  color: #eae8e8;
}

.txt--grey {
  color: #a9a9a9;
}

.txt--green {
  color: #02c853;
}

.txt--blue {
  color: #146d99;
}


.table {
  width: 100%;
  text-align: center;
  color: #222222;
}

.table tr:nth-of-type(odd) {
  background: #e7e7e8;
}

.table tr .txt-link {
  display: inline-block;
}

.table th {
  height: 60px;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .table th {
    font-size: 16px;
    /* font-size: 1.8rem; */
  }
}

.table td {
  height: 50px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (min-width: 768px) {
  .table td {
    font-size: 16px;
    /* font-size: 2rem; */
  }
}

.table th,
.table td {
  line-height: 1.2;
  vertical-align: middle;
  padding: 5px;
}

@media screen and (max-width: 480px) {

  .table th,
  .table td {
    font-size: 13px;
    font-size: 1.0rem;
  }
}

.section--white {
  background: #fff;
}

.section--gray {
  background: #edefef;
}

.section--btn {
  text-align: center;
}

.section--highlight {
  background-color: #d9edf2;
}

@media screen and (min-width: 768px) {
  .section--highlight .title--third {
    margin-bottom: 30px;
  }
}

.section--resault {
  margin-bottom: 40px;
}

.section--resault .section--highlight {
  background-color: #fff;
  border: solid 1px #bcbcbc;
}

.section--resault .add-order__highlight--list {
  border-bottom: solid 1px #cecece;
}

.section--table {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .section--table {
    margin-top: 40px;
  }
}

.section--table .td-btn .btn {
  height: 34px;
  line-height: 1;
}

@media screen and (max-width: 420px) {
  .section--table .td-btn .btn {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 768px) {
  .section--table .td-btn .btn {
    margin: 0;
  }
}

@media screen and (min-width: 960px) {
  .section--table .td-btn .btn {
    min-width: 80px;
  }
}

.section--paging {
  text-align: center;
}

.section--paging:before,
.section--paging:after {
  content: " ";
  display: table;
}

.section--paging:after {
  clear: both;
}

@media screen and (min-width: 960px) {
  .section--paging {
    text-align: right;
  }

  .section--paging .pagination {
    display: block;
  }
}

.pagination li {
  display: inline-block;
  text-align: center;
}

.pagination li a {
  color: #155169;
  height: 45px;
  line-height: 26px;
  display: block;
  padding: 8px 20px 10px;
  border: solid 1px #155169;
  border-radius: 5px;
  background-color: #e7e7e8;
  font-size: 18px;
  /* font-size: 1.8rem; */
}

@media screen and (max-width: 440px) {
  .pagination li a {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 6px 11px 8px;
    line-height: 32px;
  }
}

@media screen and (max-width: 320px) {
  .pagination li a {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 6px 8px 8px;
  }

  .pagination li a i {
    display: none;
  }
}

.pagination li a:hover {
  border: solid 1px #155169;
  background-color: #155169;
  color: #fff;
  text-decoration: none;
}

.main-content {
  background: #f5f5f5;
  padding: 20px 15px;
}

@media screen and (min-width: 960px) {
  .main-content {
    padding: 30px;
  }
}


/* demo1.1 - Order - Add */

.add-order__box {
  border: solid 1px #b4b4b5;
  margin-bottom: 30px;
}

.add-order__box .title--second {
  padding: 20px 15px;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .add-order__box .title--second {
    padding: 20px 30px;
  }
}

.add-order__formsecond {
  position: relative;
}

.add-order__formsecond--time {
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 330px) {
  .add-order__formsecond--time {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .add-order__formsecond--time {
    font-size: 22px;
  }
}

.add-order__formsecond--time span,
.add-order__formsecond--time strong {
  display: inline-block;
  height: 45px;
  line-height: 45px;
  vertical-align: top;
}

@media screen and (min-width: 640px) {

  .add-order__formsecond--time span,
  .add-order__formsecond--time strong {
    width: auto;
    text-align: left;
  }
}

.add-order__formsecond--time span {
  background: #d9edf2;
  color: #222222;
  padding: 0 10px 0 15px;
}

@media screen and (max-width: 639px) {
  .add-order__formsecond--time span {
    width: 65%;
    padding-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .add-order__formsecond--time span {
    padding: 0 20px 0 33px;
  }
}

.add-order__formsecond--time strong {
  font-size: 1.2em;
  padding: 0 25px;
  background: #459e75;
  color: #fff;
}

@media screen and (max-width: 639px) {
  .add-order__formsecond--time strong {
    width: 35%;
    text-align: center;
    padding: 0;
  }
}

.add-order__formsecond--list {
  padding: 20px 15px;
}

.add-order__formsecond--list:before,
.add-order__formsecond--list:after {
  content: " ";
  display: table;
}

.add-order__formsecond--list:after {
  clear: both;
}

.add-order__formsecond--list li {
  font-size: 14px;
  color: #222222;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .add-order__formsecond--list li {
    font-size: 18px;
  }
}

.add-order__formsecond--list li strong,
.add-order__formsecond--list li span {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
}

.add-order__formsecond--list li strong {
  color: #146d99;
}

.add-order__formsecond--list li .input {
  border-radius: 6px;
  color: #222222;
  font-weight: 700;
  text-align: center;
  font-size: 2.2;
  height: 60px;
  margin: 5px auto;
}

@media screen and (min-width: 768px) {
  .add-order__formsecond--list li .input {
    font-size: 30px;
    width: 80%;
  }
}

.add-order__formsecond--total {
  position: absolute;
}

.add-order__formsecond--total strong,
.add-order__formsecond--total span {
  display: inline-block;
  height: 60px;
  line-height: 55px;
  font-size: 18px;
  font-weight: 700;
}

.add-order__formsecond--total strong {
  background: #d9edf2;
  margin-top: 1px;
  color: #222222;
}

@media screen and (max-width: 767px) {
  .add-order__formsecond--total strong {
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .add-order__formsecond--total strong {
    padding: 0 40px;
  }
}

.add-order__formsecond--total span {
  font-size: 16px;
  position: relative;
  top: -1px;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .add-order__formsecond--total span {
    height: auto;
    line-height: 1.5;
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) {
  .add-order__formsecond--total span {
    padding: 0 10px;
  }
}

.add-order__formsecond--total b {
  font-size: 2em;
}

.add-order__expand {
  clear: left;
  margin-bottom: 20px;
}

.add-order__expand .title--second {
  color: #222222;
  padding-left: 15px;
}

@media screen and (max-width: 767px) {
  .add-order__expand .title--second {
    padding-bottom: 10px;
  }
}

.title--third {
  font-size: 18px;
  color: #222222;
}

@media screen and (min-width: 768px) {
  .title--third {
    font-size: 22px;
  }
}

.txt-green {
  color: #459e75 !important;
}

.txt-blue {
  color: #146d99 !important;
}

.txt-red {
  color: #ed1c24 !important;
}

.txt-link {
  color: #155169;
  text-decoration: underline;
  font-weight: 700;
  font-size: 16px;
  font-size: 1.6rem;
}

.txt-link:hover {
  color: #155169;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .txt-link {
    font-size: 16px;
    /* font-size: 2.1rem; */
  }
}

.title--primarycolor {
  color: #155169;
}

.add-order__highlight--top .add-order__highlight--zoom.no-icon i {
  color: #5c5c5c;
  position: absolute;
  bottom: 7px;
  z-index: 1;
  left: 7px;
}

@media screen and (min-width: 768px) {
  .add-order__highlight--second {
    padding-top: 20px;
    padding-left: 40px !important;
  }

  .add-order__highlight--second>.row {
    margin-left: 0;
    margin-right: 0;
  }
}

ul.add-order__highlight--list {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #fff;
}

.add-order__highlight--list:before,
.add-order__highlight--list:after {
  content: " ";
  display: table;
}

.add-order__highlight--list:after {
  clear: both;
}

.add-order__highlight--list li {
  float: left;
}

@media screen and (max-width: 340px) {
  .add-order__highlight--list li {
    font-size: 13px;
  }
}

.add-order__highlight--list li span,
.add-order__highlight--list li strong {
  display: block;
}

.add-order__highlight--list li span {
  margin-bottom: 5px;
}

.add-order__highlight--list li strong {
  color: #222222;
  font-size: 14px;
}

@media screen and (min-width: 640px) {
  .add-order__highlight--list li strong {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .add-order__highlight--list li strong {
    font-size: 20px;
  }
}

.add-order__highlight--list li img {
  margin-right: 10px;
}

.add-order__highlight--list li .flag {
  width: 50px;
  border: solid 1px #c1c1c2;
}

@media screen and (max-width: 340px) {
  .add-order__highlight--list li .flag {
    width: 50px;
    max-width: 55px;
  }
}

@media screen and (max-width: 767px) {
  .programtour-list .btn__wrap {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .programtour-list .btn__wrap {
    padding-top: 25px;
  }
}

.programtour-list .btn__wrap .btn {
  width: auto;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .programtour-list .btn__wrap .btn {
    width: 50%;
    float: left;
  }
}

.programtour-list .btn__wrap .btn--text {
  padding: 0;
}

.programtour-list [type="date"] {
  background: url(/assets/img/icon-calendar.png) no-repeat right center;
  background-size: 31px;
}

.programtour-list [type="date"]::-webkit-inner-spin-button {
  display: none;
}

.programtour-list [type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.period-table .text-success {
  color: #459e75 !important;
}

.period-table .text-warning {
  color: #222222 !important;
}

.period-table .text-default {
  color: #222222 !important;
}

.period-table .text-info {
  color: #222222 !important;
}

.period-table .text-danger {
  color: #ed1c24 !important;
}

.selected-list .c-angle-down svg,
.selected-list .c-angle-up svg {
  display: none !important;
}

.selected-list .c-angle-down,
.selected-list .c-angle-up {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  right: -1px !important;
  transform: translateY(-50%) scaleX(0.6) !important;
  font-size: 1.1em !important;
}

.selected-list .c-angle-down::before,
.selected-list .c-angle-up::before {
  content: "\f0dc";
}

.series-update .section {
  margin-bottom: 20px;
}

.series-update .section:nth-child(2) {
  padding-top: 20px;
  padding-bottom: 20px;
}

.series-update .section--table {
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .series-update .btn__wrap {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .series-update .btn__wrap {
    padding-top: 25px;
  }
}

.series-update .btn__wrap .btn {
  width: auto;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .series-update .btn__wrap .btn {
    width: 50%;
    float: left;
  }
}

@media screen and (max-width: 640px) {
  .series-update .btn__wrap .btn {
    width: 40%;
  }
}

.series-update .btn__wrap .btn--text {
  padding: 0;
}

.copy-link {
  padding: 15px;
}

@media screen and (min-width: 960px) {
  .copy-link .title {
    display: none;
  }
}

.copy-link .input {
  width: 100%;
  height: 56px;
  background: #efefef;
  border-radius: 6px;
  font-size: 1.2em;
}

@media screen and (min-width: 768px) {
  .copy-link .input {
    width: 69%;
    margin-right: 2%;
    float: left;
  }
}

@media screen and (min-width: 960px) {
  .copy-link .input {
    width: 69%;
  }
}

.copy-link .btn {
  width: 200px;
  float: right;
  line-height: 1.2;
  font-weight: 700;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .copy-link .btn {
    width: 25%;
  }
}

.title--head:before,
.title--head:after {
  content: " ";
  display: table;
}

.title--head:after {
  clear: both;
}

@media screen and (max-width: 767px) {
  .title--head {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  .title--head .title {
    width: 70%;
  }
}

.title--head .btn {
  width: 200px;
  font-size: 1.25em;
  font-weight: 700;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .title--head .btn {
    width: 26%;
    float: right;
  }
}

@media screen and (min-width: 960px) {
  .title--head .btn {
    width: 28%;
  }
}

@media screen and (min-width: 1240px) {
  .title--head .btn {
    position: relative;
    top: -16px;
  }
}

.title--second span {
  font-size: 0.8em;
  color: #222222;
  margin-left: 10px;
  display: inline-block;
}

.addprogram-btn:before,
.addprogram-btn:after {
  content: " ";
  display: table;
}

.addprogram-btn:after {
  clear: both;
}

@media screen and (min-width: 960px) {
  .addprogram-btn {
    float: right;
    position: absolute;
    right: 30px;
    top: 19px;
  }
}

.addprogram-btn li {
  display: block;
  float: left;
  position: relative;
  top: -11px;
}

@media screen and (min-width: 960px) {
  .addprogram-btn li {
    top: 0;
  }
}

.addprogram-btn__bt {
  display: inline-block;
  width: auto;
  height: 55px;
  margin: 0 3px;
  padding: 0 15px;
  text-align: center;
  border-radius: 8px;
  color: #fff;
  line-height: 1.25;
}

.addprogram-btn__bt--blue {
  background: #00add8;
}

.addprogram-btn__bt--dark_blue {
  background: #3A83A3;
}

.addprogram-btn__bt--yellow {
  background: #eb9e3c;
}

.addprogram-btn__bt--red {
  background: #870a0a;
}

.addprogram-btn__bt:disabled,
.addprogram-btn__bt--blue:disabled,
.addprogram-btn__bt--yellow:disabled,
.addprogram-btn__bt--red:disabled {
  background: #ccc;
  pointer-events: none;
}

@media screen and (max-width: 340px) {
  .addprogram-btn__bt {
    font-size: 9px;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 768px) {
  .addprogram-btn__bt {
    width: 120px;
  }
}

@media screen and (min-width: 1240px) {
  .addprogram-btn__bt {
    width: 155px;
  }
}

.view-programtour__list>strong {
  display: inline-block;
  margin-bottom: 20px;
  color: #222222;
  font-size: 1.1em;
}

.text-link,
.text-link:hover,
.text-link:active,
.text-link:focus {
  color: #146d99;
  text-decoration: underline;
  font-size: 1.1em;
  font-weight: 700;
  float: right;
  display: inline-block;
}

.add-reriod__checkcopy {
  padding: 20px 15px;
}

.add-reriod__checkcopy:before,
.add-reriod__checkcopy:after {
  content: " ";
  display: table;
}

.add-reriod__checkcopy:after {
  clear: both;
}

@media screen and (min-width: 960px) {
  .add-reriod__checkcopy {
    padding: 25px 70px;
  }
}

.add-reriod__checkcopy .label {
  margin: 0;
  font-size: 1.1em;
}

@media screen and (max-width: 440px) {
  .add-reriod__checkcopy .label {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 375px) {
  .add-reriod__checkcopy .label {
    font-size: 0.7em;
  }
}

.add-reriod__checkcopy .select {
  background: #f4f4f4;
}

@media screen and (max-width: 767px) {
  .add-reriod__checkcopy .select {
    margin-top: 25px;
  }
}

@media screen and (min-width: 768px) {
  .add-reriod__checkcopy .select {
    width: 300px;
    display: inline-block;
    margin-left: 15px;
    position: relative;
    top: 6px;
  }
}

@media screen and (min-width: 768px) {
  .add-reriod__formfirst {
    padding-right: 15px;
  }
}

.add-reriod__formfirst strong {
  display: inline-block;
  color: #222222;
  margin-bottom: 15px;
  font-size: 1.2em;
}

.add-reriod__formfirst--top {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .add-reriod__formfirst--top {
    padding-right: 18%;
  }
}

.add-reriod__formfirst--right {
  padding: 15px 5px;
  background: #fff;
  border: solid 1px #a7a7a8;
}

@media screen and (min-width: 768px) {
  .add-reriod__formfirst--right {
    padding: 20px 10px;
  }
}

@media screen and (min-width: 1240px) {
  .add-reriod__formfirst--right {
    padding: 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  .add-reriod__formfirst--left {
    margin-bottom: 20px;
  }
}

.add-reriod__formfirst--second {
  position: relative;
}

.add-reriod__formfirst--second:before {
  content: "+";
  position: absolute;
  left: 39.5%;
  top: 30px;
  font-size: 1.2em;
}

.add-reriod__formsecond .select,
.add-reriod__formsecond .select2,
.add-reriod__formsecond .input {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .add-reriod__formsecond--left {
    border-bottom: solid 1px #cecece;
    padding-bottom: 15px;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 768px) {
  .add-reriod__formsecond--left {
    padding-right: 50px;
    border-right: solid 1px #cecece;
  }
}

@media screen and (min-width: 768px) {
  .add-reriod__formsecond--right {
    padding-left: 50px;
  }
}

.add-reriod__formsecond--list {
  padding-top: 20px;
}

.add-reriod__formsecond--list:before,
.add-reriod__formsecond--list:after {
  content: " ";
  display: table;
}

.add-reriod__formsecond--list:after {
  clear: both;
}

.add-reriod__formsecond--list li {
  float: left;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .add-reriod__formsecond--list li {
    width: 45%;
  }
}

@media screen and (min-width: 768px) {
  .add-reriod__formsecond--list li:nth-child(odd) {
    float: right;
  }
}

.add-reriod__formsecond--bottom {
  padding: 10px 15px 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .add-reriod__formsecond--bottom {
    padding-top: 40px;
  }
}

.add-reriod .section--btn {
  padding-top: 0;
}

.mgt-20 {
  margin-top: 40px;
}

.table--add-reriod {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .table--add-reriod {
    font-size: 0.9em;
  }
}

.table--add-reriod th {
  height: 30px;
  font-weight: 700;
  text-align: center;
}

.table--add-reriod td {
  height: 50px;
  padding: 5px;
}

.table--add-reriod td:nth-child(n+2) {
  width: 30%;
}

@media screen and (min-width: 768px) {
  .table--add-reriod td {
    padding-bottom: 20px;
  }
}

.table--add-reriod td .label {
  margin-right: 0;
}

@media screen and (max-width: 640px) {
  .table--add-reriod td .label {
    font-size: 0.9em;
  }
}

.table--add-reriod td .input {
  font-weight: 700;
  color: #222222;
  text-align: center;
  padding: 5px 5px 5px 0;
}

.table--add-reriod td .input::placeholder {
  color: #222222;
}

.table--add-reriod th,
.table--add-reriod td {
  line-height: 1.2;
  vertical-align: middle;
}

@media screen and (max-width: 400px) {
  .table--add-reriod .form__half--check input[type="checkbox"]+span {
    width: 18px;
    height: 18px;
    margin-right: 2px;
    top: 4px;
  }
}

.input--gray {
  background: #f4f4f4;
}

.addprogram-btn:before,
.addprogram-btn:after {
  content: " ";
  display: table;
}

.addprogram-btn:after {
  clear: both;
}

@media screen and (min-width: 960px) {
  .addprogram-btn {
    float: right;
    position: absolute;
    right: 30px;
    top: 19px;
  }
}

.addprogram-btn li {
  display: block;
  float: left;
  position: relative;
  top: -11px;
}

@media screen and (min-width: 960px) {
  .addprogram-btn li {
    top: 0;
  }
}

@media screen and (max-width: 340px) {
  .addprogram-btn__bt {
    font-size: 9px;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 768px) {
  .addprogram-btn__bt {
    width: 120px;
  }
}

@media screen and (min-width: 1240px) {
  .addprogram-btn__bt {
    width: 155px;
  }
}

.txt-green {
  color: #459e75;
}

.txt-blue {
  color: #146d99;
}

.txt-red {
  color: #ed1c24;
  background: transparent;
}

.text-link {
  color: #146d99;
  text-decoration: underline;
  font-size: 1.1em;
  font-weight: 700;
  float: right;
  display: inline-block;
  padding: 10px 0;
}

.title--sub {
  margin-bottom: 20px;
  display: block;
}

@media screen and (min-width: 768px) {
  .title--sub {
    margin-bottom: 40px;
  }
}

.view-period {
  line-height: 1.4;
  margin-bottom: 40px;
}

.view-period__table tr:nth-child(even)>td {
  background: #faf8f8;
}

.view-period__table--one td {
  width: 15%;
}

.view-period__table--two td {
  width: 20%;
  font-weight: 700;
}

.view-period__table--two td:first-child {
  width: 35%;
}

.view-period__comit {
  margin-bottom: 20px;
}

.view-period__flight {
  margin-bottom: 30px;
}

.view-period__flight div {
  margin-top: -20px;
}

.view-period__flight div img {
  position: relative;
  top: 15px;
  margin-left: 15px;
  width: 70px;
}

.view-period__flight ul li {
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .add-passenge__formtop .row {
    margin-bottom: 20px;
  }
}

.add-passenge__mgt {
  padding-top: 10px;
}

.add-passenge__formthird .textarea {
  height: 106px;
}

.add-passenge .row--third {
  position: relative;
}

@media screen and (min-width: 768px) {
  .add-passenge .row--third div:nth-child(n+2) {
    width: 37.5%;
  }
}

.add-passenge .title--second {
  color: #222222;
  text-decoration: none;
}

.add-passenge .section--btn {
  padding-top: 0;
}

@media screen and (max-width: 767px) {
  .add-passenge .section--btn .btn {
    width: 49%;
    display: inline-block;
  }
}

.add-passenge .download-programtour li {
  padding-left: 0;
}

.download-programtour:before,
.download-programtour:after {
  content: " ";
  display: table;
}

.download-programtour:after {
  clear: both;
}

@media screen and (max-width: 767px) {
  .download-programtour li {
    margin-bottom: 15px;
    padding: 0;
  }
}

.download-programtour li .box {
  display: block;
  border: dashed 2px #cecece;
  text-align: center;
  padding: 15px 0;
}

.download-programtour li img {
  display: block;
  margin: 0 auto 10px;
}

.download-programtour li .span {
  display: inline-block;
  max-width: 160px;
  width: 100%;
  background: #146d99;
  color: #fff;
  border-radius: 2px;
  padding: 5px 0;
}

.download-programtour:before,
.download-programtour:after {
  content: " ";
  display: table;
}

.download-programtour:after {
  clear: both;
}

.download-programtour li {
  position: relative;
}

.download-programtour li .zoom {
  position: absolute;
  text-align: center;
  border: none;
  width: 88%;
  bottom: 0;
  padding: 5px 0;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .download-programtour li .zoom {
    width: 89%;
    left: 22px;
  }
}

.download-programtour li .zoom:before {
  content: "";
  background: url(assets/img/icon-zoom.png) no-repeat;
  background-size: 100%;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

@media screen and (max-width: 767px) {
  .download-programtour li {
    margin-bottom: 15px;
    padding: 0;
  }
}

.download-programtour li .upload {
  display: block;
  background: #fff;
  border: dashed 2px #cecece;
  text-align: center;
  padding: 15px 0;
}

.download-programtour li img {
  display: block;
  margin: 0 auto 10px;
}

.download-programtour li span {
  display: inline-block;
  max-width: 160px;
  width: 100%;
  background: #146d99;
  color: #fff;
  border-radius: 2px;
  padding: 5px 0;
}

.download-programtour li .imgblock {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .download-programtour li .imgblock {
    padding: 0 20px;
  }
}

.download-programtour .download {
  color: #146d99;
  text-decoration: underline;
  font-weight: 700;
  display: inline-block;
  font-size: 1em;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .download-programtour .download {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 960px) {
  .download-programtour .download {
    font-size: 1.1em;
    margin: 5px 0 10px;
  }
}

.check-passport {
  vertical-align: middle;
  font-size: 1.1em;
  color: #146d99;
  font-weight: 700;
  /* position: relative; */
}


/* @media screen and (max-width: 767px) {
  .check-passport {
    top: -15px;
    left: 20px;
  }
}

@media screen and (min-width: 768px) {
  .check-passport {
    position: absolute;
    top: 65px;
    left: 27%;
  }
}

@media screen and (min-width: 1240px) {
  .check-passport {
    left: 0%;
  }
} */

.check-passport img {
  vertical-align: middle;
  margin-right: 10px;
}


/* @media screen and (max-width: 767px) {
  .check-passport img {
    width: 25px;
    top: 6px;
  }
} */

.select-date .col {
  padding: 0 5px;
}

@media screen and (max-width: 959px) {
  .select-date .select {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (min-width: 768px) {
  .section--btn .btn--primary {
    width: 250px;
  }
}

.modal-dialog.modal-xl {
  max-width: 90%;
}

.modal-dialog .modal-content {
  min-width: 100%;
  font-size: 14px;
}

.text-red {
  color: #FF0000;
}

.text-black {
  color: #222;
}

.view {
  display: block;
  font-size: 1em;
  color: #5c5c5d;
  font-weight: 700;
  margin-top: 10px;
  word-wrap: break-word;
}

.view span {
  color: #146d99;
  display: block;
  margin-top: 5px;
}

.view--pass {
  position: relative;
}

.view--pass:after {
  content: "";
  position: absolute;
  background: url(assets/img/icon-plus-green.png) no-repeat;
  background-size: 100%;
  width: 42px;
  height: 42px;
  top: -12px;
  right: 20px;
}

.view-passenger {
  padding-top: 50px;
}

@media screen and (min-width: 960px) {
  .view-passenger {
    padding-top: 80px;
  }
}

.view-passenger .addprogram-btn {
  top: 65px;
}

.view-passenger .label {
  color: #222222;
}

@media screen and (max-width: 767px) {
  .view-passenger .row--third .view {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .view-passenger .row--third div:nth-child(n+2) {
    width: 37.5%;
  }
}

.view-passenger .table {
  border: solid 1px #a7a7a8;
  margin-top: 20px;
}

.view-passenger .table th,
.view-passenger .table td {
  width: 15%;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow-wrap: break-word;
}

@media screen and (max-width: 480px) {

  .view-passenger .table th,
  .view-passenger .table td {
    font-size: 0.8em;
  }
}

.view-passenger .table th:first-child,
.view-passenger .table td:first-child {
  width: 25%;
}

.view-passenger .table th:nth-child(2),
.view-passenger .table td:nth-child(2) {
  width: 30%;
  text-align: left;
}

.view-passenger .table th:last-child,
.view-passenger .table td:last-child {
  width: 30%;
}

.view-passenger .table th a,
.view-passenger .table td a {
  color: #155169;
  font-weight: 700;
  text-decoration: underline;
  font-size: 0.9em;
}

@media screen and (max-width: 480px) {

  .view-passenger .table th a,
  .view-passenger .table td a {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 480px) {

  .view-passenger .table .th__last div,
  .view-passenger .table .tb__last div {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 768px) {

  .view-passenger .table .th__last div,
  .view-passenger .table .tb__last div {
    width: 50%;
    float: left;
  }
}

@media screen and (max-width: 480px) {
  .view-passenger .table .th__last div:first-child {
    width: 50%;
    margin: auto;
  }
}

@media screen and (max-width: 480px) {
  .view-passenger .table .th__last div:last-child {
    display: none;
  }
}

.table thead th {
  vertical-align: middle;
}

.btn-country-filter {
  margin-right: 4px;
  margin-bottom: 4px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100px;
  height: 40px;
  color: #146d99;
  background-color: #f5f5f5;
  border-color: #146d99;
  font-weight: bold;
  border-radius: 0px;
}

.btn-country-filter:active,
.btn-country-filter:focus,
.btn-country-filter-active {
  background-color: #daedf3;
}

@media screen and (max-width: 576px) {
  .btn-country-filter {
    width: 31.5%;
  }
}

@media screen and (min-width: 576px) {
  .btn-country-filter {
    width: 100px;
  }
}

.loadPDF-programtour {
  /* margin-bottom: 10px; */
}

.loadPDF-programtour span {
  display: block;
  color: #155169;
  font-weight: bold;
  font-size: 14px;
}

.loadPDF-programtour li span {
  color: #5c5c5d;
  font-size: 12px;
}

.loadPDF-programtour li a.disabled {
  cursor: default;
}

.loadPDF-programtour li a.disabled span,
.loadPDF-programtour li a.disabled i {
  opacity: 0.5;
}

.loadPDF-programtour a:hover,
.loadPDF-programtour a:focus {
  text-decoration: none;
  color: inherit;
}

.loadPDF-programtour i {
  margin-bottom: 5px;
}

@media screen and (max-width: 375px) {
  .loadPDF-programtour span {
    font-size: 14px;
  }
}

.loadPDF-programtour ul:before,
.loadPDF-programtour ul:after {
  content: " ";
  display: table;
}

.loadPDF-programtour ul:after {
  clear: both;
}

.loadPDF-programtour ul li {
  display: inline-block;
  padding: 5px;
  float: left;
  width: 33.33%;
  max-width: 35px;
  text-align: center;
}

@media screen and (min-width: 1240px) {
  .loadPDF-programtour ul li {
    margin-right: 5px;
    max-width: 40px;
  }
}

.airline {
  /*margin-top: 20px;*/
  width: 100%;
  position: absolute;
  bottom: 0;
}

@media screen and (min-width: 768px) {
  .airline {
    position: absolute;
    /* remove comment this line*/
    bottom: 0;
  }
}

.airline:before,
.airline:after {
  content: " ";
  display: table;
}

.airline:after {
  clear: both;
}

.airline ul,
.airline div {
  display: inline-block;
  width: 100%;
  float: left;
}

@media screen and (min-width: 768px) {

  .airline ul,
  .airline div {
    display: flex;
    align-items: center;
    /*min-height: 93px;*/
  }
}

@media screen and (min-width: 768px) {
  .airline ul {
    width: 36%;
  }
}

.airline ul li {
  display: inline-block;
  padding: 5px;
  padding-bottom: 0px;
  /* width: 33.33%; */
  float: left;
}

@media screen and (min-width: 768px) {
  .airline div {
    width: 64%;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}

@media screen and (max-width: 639px) {
  .airline div {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.airline2 {
  display: inline-block;
}

.airline2 li {
  display: inline-block;
}

.airline2 li img {
  width: 30px;
  position: relative;
  top: 5px;
}

.text-link i.arr {
  position: relative;
}

.text-link i.arr:before {
  content: "▼";
  font-size: 0.7em;
}

.text-link i.arr.arr--hide:before {
  content: "▲";
}

.txt-yellow {
  color: #e7a30f !important;
}

.booking-list .section {
  padding: 15px;
  padding-bottom: 10px;
}

.booking-list .btn--loaddata {
  line-height: 52px;
  position: relative;
  top: -5px;
  max-width: 380px;
  margin: 0 auto 15px;
  display: block;
}

@media screen and (min-width: 768px) {
  .booking-list .btn--loaddata {
    margin: 0;
    float: right;
  }
}

.booking-list .title--booking {
  color: #5c5c5d;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (min-width: 768px) {
  .booking-list .title--booking {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.booking-list__form {
  font-size: 16px;
  font-weight: 700;
}

.booking-list__form:before,
.booking-list__form:after {
  content: " ";
  display: table;
}

.booking-list__form:after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .booking-list__form {
    font-size: inherit;
  }
}

.booking-list__form .text-link {
  float: left;
}

.booking-list__form .btn--text {
  float: right;
  padding: 0;
  min-width: 0;
  width: auto;
  height: inherit;
  position: relative;
  top: 3px;
}

@media screen and (min-width: 640px) {
  .booking-list__form .btn--text {
    top: 0;
  }
}

.booking-list__form--expand {
  clear: both;
  margin: 0 -15px;
  padding: 15px 0 0;
}

.booking-list__form--expand ul:before,
.booking-list__form--expand ul:after {
  content: " ";
  display: table;
}

.booking-list__form--expand ul:after {
  clear: both;
}

@media screen and (min-width: 640px) {
  .booking-list__form--expand ul li {
    display: flex;
    flex-direction: inherit;
    align-items: center;
    margin-bottom: 10px;
  }

  .booking-list__form--expand ul li:nth-child(1) {
    justify-content: flex-end;
  }

  .booking-list__form--expand ul li:nth-child(1) .select {
    width: 50%;
  }

  .booking-list__form--expand ul li:nth-child(odd) {
    clear: left;
    float: left;
  }

  .booking-list__form--expand ul li .label {
    width: inherit;
  }
}

.booking-list__country {
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 10px;
}

.booking-list__country:before,
.booking-list__country:after {
  content: " ";
  display: table;
}

.booking-list__country:after {
  clear: both;
}

.booking-list__country--list {
  display: inline-block;
  text-align: center;
  position: relative;
  padding: 0 5px;
  margin-bottom: 10px;
}

.booking-list__country--list input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.booking-list__country--list input:checked+label {
  background-color: #d0ebf4;
}

.booking-list__country--list label {
  background-color: #f5f5f5;
  border: solid 2px #146d99;
  color: #146d99;
  text-align: center;
  display: inline-block;
  height: 42px;
  line-height: 1em;
  padding: 10px 20px;
  font-weight: 700;
  cursor: pointer;
}

.booking-list__select li {
  display: block;
  margin-bottom: 10px;
}

.booking-list__select li .select {
  height: 50px;
}

.booking-list__select li .select--active {
  color: #5c5c5d;
  border-color: #5c5c5d;
}

.booking-list__table:before,
.booking-list__table:after {
  content: " ";
  display: table;
}

.booking-list__table:after {
  clear: both;
}

.booking-list__table .title--head {
  padding-top: 15px;
  margin-bottom: 0;
}

.booking-list__table .title--head .title {
  margin-bottom: 10px;
}

.booking-list__table .title--head span {
  display: block;
  color: #8f8f90;
  font-size: 1.1em;
  position: relative;
  top: -8px;
}

@media screen and (min-width: 768px) {
  .booking-list__table .title--head {
    padding: 25px 0 20px;
  }

  .booking-list__table .title--head:before,
  .booking-list__table .title--head:after {
    content: " ";
    display: table;
  }

  .booking-list__table .title--head:after {
    clear: both;
  }

  .booking-list__table .title--head .title {
    float: left;
    width: auto;
    margin-bottom: 0;
  }

  .booking-list__table .title--head .select {
    float: right;
    width: 240px;
  }

  .booking-list__table .title--head span {
    float: right;
    top: 4px;
  }

  .booking-list__table .pagination {
    float: right;
  }
}

.table__listview {
  overflow: hidden;
  overflow-x: auto;
  margin-bottom: 20px;
}

.table__listview .table {
  margin-bottom: 0;
}

@media screen and (max-width: 1240px) {
  .table__listview .table {
    width: 900px;
  }
}

.table__listview .table tr {
  /* border-bottom: solid 10px #f5f5f5; */
  width: 100%;
}

.table__listview .table tr:nth-child(1),
.table__listview .table tr:last-child {
  border-bottom: 0;
}

.table__listview .table th {
  background-color: #cae5ef;
  color: #146d99;
  text-align: left;
  height: 40px;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .table__listview .table th {
    font-size: 16px;
    padding-left: 0;
  }
}

.table__listview .table td {
  background-color: #fff;
  text-align: left;
  padding: 10px 0;
  vertical-align: top;
  line-height: 1.4;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .table__listview .table td {
    font-size: 15px;
  }
}

.table__listview .table td>strong,
.table__listview .table td>span {
  display: block;
  width: 100%;
  padding: 0 5px;
}

.table__listview .table td>strong span {
  display: block;
  padding: 2px 0;
}

.table__listview .table td>span {
  /* border-top: solid 1px #dbdbdb; */
  padding-top: 10px;
  color: #8f8f90;
}

.table__listview .btn.btn--second {
  min-width: 155px;
  margin: 10px 0;
}

.order-list__advance-search {
  font-size: 16px !important;
}

.booking-list__form--expand .select {
  font-size: 16px !important;
}

.program-list__highlight {
  position: relative;
}

.program-list__highlight:before,
.program-list__highlight:after {
  content: " ";
  display: table;
}

.program-list__highlight:after {
  clear: both;
}

.program-list__highlight .text-link--bold {
  display: block;
  font-size: 14px;
  padding: 0;
  min-width: 100%;
  text-align: right;
}

@media screen and (min-width: 768px) {
  .program-list__highlight .text-link--bold {
    float: right;
  }
}

.program-list__highlight--count {
  /*margin-bottom: 50px;*/
}

.program-list__highlight--count strong {
  color: #155169;
  font-size: 25px;
  word-break: break-word;
  display: block;
}

.program-list__highlight--count span {
  font-size: 10px;
  color: #949292;
}

.program-list__highlight--type {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .program-list__highlight--count span {
    display: block;
  }
}

.program-list__highlight--head {
  /*margin-bottom: 15px;*/
}

@media screen and (min-width: 960px) {
  .program-list__highlight--head {
    width: 61%;
  }
}

.program-list__highlight--content {
  display: block;
  width: 85%;
  line-height: 22px;
}

.program-list__highlight--content.expanded {
  height: auto !important;
}

.program-list__highlight--content.hidden {
  visibility: hidden;
  position: absolute;
}

.program-list__highlight--head h3 {
  color: #146d99;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.program-list__highlight--head p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 44px;
  padding-left: 25px;
  position: relative;
}

.program-list__highlight--head p i {
  position: absolute;
  top: 3px;
  left: 0;
}

.program-list__highlight--head p a {
  position: absolute;
  right: 0;
  bottom: 1px;
  background-color: #daedf3;
  padding: 0 9px;
  display: inline-block;
}

.program-list__highlight--head p.active {
  max-height: inherit;
  display: block;
}

.program-list__highlight--head .text-link {
  font-size: 14px;
  display: inline-block;
  float: none;
}

.program-list__highlight--head .text-link i {
  font-size: 0.5em;
  position: relative;
  left: 0;
  top: 0;
  font-style: normal;
}

@media screen and (min-width: 960px) {
  .program-list__highlight--pdf .row>div:first-child {
    width: 24%;
  }

  .program-list__highlight--pdf .row>div:nth-child(2) {
    width: 74%;
  }
}

@media screen and (min-width: 768px) {
  .program-list__highlight--detail {
    position: absolute;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .program-list__highlight--img {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 414px) {
  .program-list__highlight--img .add-order__highlight--zoom {
    font-size: 13px;
  }
}

.program-list__highlight--img .add-order__highlight--top {
  margin-bottom: 10px;
  width: 158px;
  float: right;
}

.program-list__highlight--img .imgblock {
  background-color: #f4f7fc;
  object-fit: cover;
  height: 105px;
}

.program-list__content {
  overflow: hidden;
  overflow-x: auto;
  margin-bottom: 20px;
}

.program-list__list {
  width: 100%;
}

@media screen and (max-width: 1240px) {
  .program-list__list {
    width: 900px;
  }
}

.program-list__list li {
  font-size: 14px;
  background-color: #fff;
  height: auto;
  display: table;
  width: 100%;
  padding: 0 10px;
  border-bottom: solid 1px #dbdbdb;
}

.program-list__list .mb-5 {
  margin-bottom: 5px !important;
  display: block;
}

.program-list__list li:before,
.program-list__list li:after {
  content: " ";
  display: table;
}

.program-list__list li:after {
  clear: both;
}

.program-list__list li.first-child {
  background-color: #146d99;
  color: #fff;
}

.program-list__list li strong,
.program-list__list li span {
  padding: 10px 5px 15px;
  display: table-cell;
  width: 8.3%;
  line-height: 1;
  text-align: center;
}

.program-list__list li strong:last-child,
.program-list__list li span:last-child {
  width: 10%;
  text-align: center;
}

.program-list__list li strong:nth-child(2),
.program-list__list li span:nth-child(2) {
  width: 17%;
}

.program-list__list li span.col-btn {
  padding-bottom: 0px;
}

.program-list__list li strong {
  vertical-align: top;
}

.program-list__list li strong.active {
  border: 1px solid #dee2e6;
  margin-bottom: 1px;
}

.program-list__list li span {
  background-color: #fff;
  vertical-align: top;
  padding-top: 15px;
  color: #5c5c5d;
}

.program-list__list li span:last-child {
  padding-top: 10px;
}

.program-list__list .btn--primary {
  height: 26px;
  line-height: 24px;
  min-width: inherit;
  padding: 0 20px;
  display: block;
  margin: auto;
  width: auto;
  font-size: 14px;
}

.program-list__list .text-link {
  font-size: 11px;
  float: none;
  position: relative;
}

.program-list__list .text-link i {
  font-size: 0.5em;
  position: absolute;
  right: -15px;
  top: 11px;
  margin-right: 0;
}

.program-list__list .table__listview {
  margin-bottom: 0;
  padding-left: 20px;
  overflow: auto;
}

.program-list__list .table__listview .table th {
  font-size: 14px;
}

.program-list__list .table__listview .table td {
  background-color: #eaeeef;
}

.program-list__list .table__listview .table .text-link {
  padding-top: 0;
}

.program-list__list .table__listview .table td>span {
  border-top: solid 1px #dbdbdb;
}

.program-list__list-all li strong,
.program-list__list-all li span {
  width: auto;
  padding-bottom: 15px;
  vertical-align: middle;
  /* border-bottom: solid 1px #f5f5f6; */
}

.program-list__list-all li strong i,
.program-list__list-all li span i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}


/* start analytics box */

.section--bigdata {
  margin-bottom: 20px;
}

.booking-list-bigdata li {
  display: inline-block;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .booking-list-bigdata li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #dbdbdb;
  }

  .booking-list-bigdata li:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .booking-list-bigdata li {
    border-right: solid 1px #dbdbdb;
    padding-left: 12px;
  }

  .booking-list-bigdata li:last-child {
    border-right: 0;
  }
}

.booking-list-bigdata li strong,
.booking-list-bigdata li span,
.booking-list-bigdata li b {
  display: block;
}

.booking-list-bigdata li strong,
.booking-list-bigdata li b {
  font-weight: 700;
}

.booking-list-bigdata li strong {
  color: #155169;
  font-size: 1.25em;
}

.booking-list-bigdata li>b {
  font-size: 1.1em;
}

@media screen and (min-width: 768px) {
  .booking-list-bigdata li>b {
    position: relative;
    top: 3px;
  }
}

.booking-list-bigdata li span {
  color: #146d99;
}

.booking-list-bigdata li span>b {
  color: #8f8f90;
  display: inline-block;
}

.booking-list-bigdata li.booking-list-bigdata__first {
  padding-left: 67px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .booking-list-bigdata li.booking-list-bigdata__first {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 960px) {
  .booking-list-bigdata li.booking-list-bigdata__first {
    margin-bottom: 0;
  }
}

.booking-list-bigdata li.booking-list-bigdata__first:before {
  content: "";
  background: url(assets/img/icon_tpa.png) no-repeat center left;
  background-size: 100%;
  width: 45px;
  height: 45px;
  display: block;
  position: absolute;
  left: 12px;
  top: 3px;
}

.booking-list-bigdata li.booking-list-bigdata__first strong i {
  color: #ef8d21;
  font-style: normal;
}

@media screen and (min-width: 960px) {
  .booking-list-bigdata--first li {
    width: 17%;
    padding-left: 10px;
    padding-right: 5px;
  }

  .booking-list-bigdata--first li:first-child {
    width: 32%;
  }
}

@media screen and (min-width: 960px) {
  .booking-list-bigdata--second li {
    width: 20%;
    padding-left: 30px;
  }

  .booking-list-bigdata--second li:first-child {
    width: 40%;
  }
}

@media screen and (min-width: 768px) {
  .booking-list-bigdata--third li {
    width: 20%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .booking-list-bigdata--third li:first-child {
    width: 100%;
  }
}

@media screen and (min-width: 960px) {
  .booking-list-bigdata--third li {
    width: 14%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .booking-list-bigdata--third li:first-child {
    width: 30%;
  }
}

.booking-list-bigdata__item {
  cursor: pointer;
}

.program-list__list li:first-child {
  background-color: #146d99;
  color: #fff;
}

.btn.btn-grey {
  background-color: #ebebed;
  color: #155169;
  border: solid 1px #155169;
  font-weight: bold;
}

/*** custom checkbox ***/
/* The container */
.checkbox {
  display: inline-flex;
  font-size: unset;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px !important;
  width: 25px !important;
  background-color: transparent;
  border: solid 1px #c1c1c2;
  border-radius: 7px;
}

.checkbox .checkbox-text {
  margin-top: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input~.checkmark {
  background-color: #fff;
  border: solid 1px #c1c1c2;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked~.checkmark {
  background-color: #136d99;
  border: 0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 7px;
  top: 1px;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox.checkbox-white input:checked~.checkmark {
  background-color: #fff;
}

.checkbox.checkbox-white input:checked~.checkmark:after {
  border: solid #136d99;
  border-width: 0 5px 5px 0;
}

/*** custom radio ***/
.radio {
  display: inline-block;
  font-size: unset;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px !important;
  width: 25px !important;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #b1b1b1;
}

.radio .radio-text {
  display: inline-flex;
  margin-top: 4px;
}

/* On mouse-over, add a grey background color */
.radio:hover input~.checkmark {
  background-color: #fff;
  border: 2px solid #b1b1b1;
}

/* When the radio button is checked, add a blue background */
.radio input:checked~.checkmark {
  background-color: #fff;
  border: 2px solid #136d99;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #136d99;
}

.submitted input.ng-invalid,
.submitted textarea.ng-invalid {
  border: 1px solid #e12d2d;
}

.red-warning {
  color: #e12d2d;
}

.modal-header,
.modal-footer {
  border: 0;
}

.modal-header {
  padding-top: 45px;
}

.modal-header h2,
.modal-footer .btn-wrapper {
  margin: auto;
}

.modal-header .close {
  font-size: 30px;
  top: 10px;
  right: 15px;
}

.text--link {
  font-weight: bold;
  color: #5b5b5c;
  background: transparent;
  text-decoration: underline;
}

.checkboxes-wrapper {
  border: 1px solid #d8dde6;
  padding: 5px;
}

textarea {
  resize: none;
}

.period-tag {
  position: absolute;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    border-color: transparent;
    border-style: solid;
  }

  &::after {
    border-width: 23px;
    border-left-color: #d8d8d8;
    border-top-color: #d8d8d8;
  }

  >span {
    z-index: 999;
    position: absolute;
    top: 10px;
    left: -10px;
    background-color: transparent !important;
    padding: 0 !important;
    transform: rotate(-45deg);
    font-size: 10px;
  }
}

.order-period-tag {
  background-color: #d8d8d8 !important;
  color: #858686 !important;
  padding: 1px 10px !important;
  display: inline !important;
}

html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  height: 100vh !important;
}
