//Google widget
.sebm-google-map-container {
    width: 100%;
    height: 300px;
    display: flex;
}

//To do list widget
.todos .checked {
    text-decoration: line-through;
}

@media(max-width:1366px) {
    .dash-chat,
    .dash-inbox {
        padding: 10px 0 0 !important;
    }
}