// Table
td {
    padding: 14px;
}

body .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    white-space: nowrap;
    font-weight: bold;
    color: $gray-dark;
}

body .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    -moz-box-align: center;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
    -moz-box-pack: start;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    max-height: 100%;
    padding: 1.2rem;
    place-content: center flex-start;
}

.datatable-body-cell-label {
    width: 100%;
    color: $gray-dark;
}

.datatable-body-cell-label>img {
    border-radius: 100%;
    width: 37px;
}

body .datatable-header-cell-label {
    font-size: 1rem;
}

.ngx-datatable {
    .meta-post {
        display: block;
        font-weight: bold;
        margin-top: 0.5rem;
    }
    .datatable-row-wrapper {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        &:last-child {
            margin-bottom: 3em;
        }
    }
}

//tables
body .ngx-datatable.material .datatable-footer .datatable-pager li.active a {
    @extend .bg-primary;
}

.app-dark {
    .ngx-datatable.material {
        color: mat-color($dark-foreground, base);
        background: mat-color($dark-background, card);
        .datatable-header .datatable-row-right,
        .ngx-datatable.material .datatable-body .datatable-row-right,
        .ngx-datatable.material .datatable-header .datatable-row-right,
        .ngx-datatable.material .datatable-body .datatable-row-right {
            background: mat-color($dark-background, card);
        }
    }
    .datatable-body-cell-label,
    .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
        color: mat-color($dark-foreground, base);
    }
    .datatable-body-row {
        &:hover {
            .datatable-body-cell-label,
            .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
                color: #000;
            }
        }
    }
}

.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==");
    background-position: 0 0;
    background-repeat: repeat-y;
}

@media(max-width:1365px) {
    .force-img-table .datatable-body-cell img:nth-last-child(-n+3) {
        display: none;
    }
}

@media(max-width:1199px) {
    .datatable-body-cell-label>img {
        width: 30px;
        margin: 0.2rem;
    }
}

@media(max-width:580px) {
    .datatable-body-cell-label>img {
        width: 20px;
    }
}