.mails-container {
    background: white;
}
.content-container {
    .search {
        background: white;
    }
}
.app-dark {
    .mails-container {
        background: mat-color($dark-background, card);
        .mails .mail{
            color: #fff !important;
            &:hover{
                color: #000 !important;
            }
        }
    }
    .content-container {
        .search {
            background: mat-color($dark-background, card);
        }
    }
    .cal-month-view .cal-cell-row:hover {
        background-color: mat-color($dark-background, card);
    }
    .cal-month-view .cal-cell-row .cal-cell:hover,
    .cal-month-view .cal-cell.cal-has-events.cal-open {
        background: mat-color($dark-background, card);
    }
    code {
        background: mat-color($dark-background, card);
    }
    .ngx-datatable.material .datatable-header .datatable-row-right,
    .ngx-datatable.material .datatable-body .datatable-row-right,
    .ngx-datatable.material .datatable-header .datatable-row-left,
    .ngx-datatable.material .datatable-body .datatable-row-left {
        background-color: mat-color($dark-background, card);
    }
    .mat-white{
        background: transparent;
    }
    .mat-card{
        background-color:#424242;
    }
}
body .app-dark .force-icon-display md-icon {
    color: mat-color($dark-foreground, base);
}
//rtl
[dir="rtl"] {
    .force-mail-wrapper {
        .mail-lebel-area .fa-circle {
            right: inherit !important;
            left: 0;
        }
        .mail-tabs-base {
            ul {
                li {
                    margin-left: 2rem;
                    margin-right: 0;
                }
            }
        }
        .mail-detail {
            .mail-header {
                .avatar {
                    margin-left: 20px;
                    margin-right: 0 !important;
                }
            }
        }
        .respond {
            .avatar {
                margin-left: 28px;
            }
        }
      .mails .end-wrapper {
  position: absolute;
  right: inherit !important;
  left:1rem !important;
}
    }
}