// Text
// Text sizes
.force-text-xs {
    font-size: 50% !important;
}

.force-text-sm {
    font-size: 70% !important;
}

.force-text-md {
    font-size: 80% !important;
}

.force-text-lg {
    font-size: 110% !important;
}

.force-text-xl {
    font-size: 120% !important;
}

//Font weight
.font-normal {
    font-weight: normal!important;
}

.font-bold {
    font-weight: 700!important;
}

.text-inverse {
    color: #fff;
}

.mat-white {
    background: #fff;
}

@include text-emphasis-variant('.mat-text-muted', mat-color($foreground, secondary-text));
@include text-emphasis-variant('.mat-text-default', mat-color($foreground, base));
@include text-emphasis-variant('.mat-text-primary', mat-color($primary));
@include text-emphasis-variant('.mat-text-warn', mat-color($warn));
@include text-emphasis-variant('.mat-text-accent', mat-color($accent));
.app-dark {
    @include text-emphasis-variant('.mat-text-muted', mat-color($dark-foreground, secondary-text));
    @include text-emphasis-variant('.mat-text-default', mat-color($dark-foreground, base));
}