.icon-member-modal {
    @include icon-modal;
    background-position: 0px 0px;
}

.icon-success-modal {
    @include icon-modal;
    background-position: -120px 0px;
}

.icon-alert-modal {
    @include icon-modal;
    background-position: -240px 0px;
}

.icon-info-modal {
    @include icon-modal;
    background-position: -360px 0px;
}
