//Header
.force-ham-icon {
    margin-right: 1rem;
}

.navbar-brand {
    color: $breadcrumb-font-color;
}

md-sidenav.sidebar-panel .sub-menu {
    padding-top: 0;
    overflow: hidden;
    -webkit-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    -moz-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    -webkit-transition-property: max-height;
    -moz-transition-property: max-height;
    transition-property: max-height;
}

md-sidenav.sidebar-panel .sub-menu a {
    margin-left: 30px;
}

md-sidenav .sidebar-panel .navigation .open>.mat-list-item-content>.sub-menu {
    max-height: 1000px;
}

.sub-menu {
    padding-top: 0;
    overflow: hidden;
    max-height: 0;
    -webkit-transition: .5s cubic-bezier(.35, 0, .25, 1);
    -moz-transition: .5s cubic-bezier(.35, 0, .25, 1);
    transition: .5s cubic-bezier(.35, 0, .25, 1);
    -webkit-transition-property: max-height;
    -moz-transition-property: max-height;
    transition-property: max-height;
}

.relative {
    position: relative;
}

body .force-header-toolbar {
    background-color: $header-bg-color;
    height: 64px;
    color: #FFF;
}

//
.app-dark {
    .force-header-toolbar {
        background: rgba(54, 54, 54, 1);
    }
    .force-breadcrumbs,
    .navbar-brand {
        color: #ffffff;
    }
}

[dir="rtl"] {
    .force-ham-icon {
        margin-left: 1rem;
    }
}
