//Sidebar vars
$sidebar-logo-align: center;
$sidebar-logo-padding: 1rem 0 0.5rem;
$sidebar-icons: 1.2rem;
$user-name-font-size: 1rem;
body {
    .sidebar-logo.mat-toolbar {
        align-items: center;
        background: transparent none repeat scroll 0 0;
        justify-content: center;
        padding: 0 6px;
    }
    .app {
        height: 100%;
        overflow: hidden;
    }
    .mat-sidenav {
        overflow-x: hidden;
        overflow-y: auto;
        min-width: 4rem;
    }
    .force-logo {
        padding: $sidebar-logo-padding;
        text-align: $sidebar-logo-align;
        .logo-text {
            margin: 0 1rem;
        }
    }
    md-sidenav .sidebar-panel .mat-nav-list a {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 2.5rem;
        padding: 0 1.5rem;
        font-weight: 500;
        margin: 0;
        color: $sidebar-text-color !important;
        font-size: 0.875rem;
        .menu-caret {
            position: absolute;
            right: 15px;
            top: 10px;
        }
    }
    .force-user-name {
        color: $sidebar-text-color;
        font-size: $user-name-font-size;
        line-height: 1.5rem;
        margin: 1rem;
        margin-bottom: 0;
        font-weight: bold;
    }
    md-sidenav .sidebar-panel .sub-menu {
        padding-top: 0;
        overflow: hidden;
        -webkit-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
        -moz-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
        transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
        -webkit-transition-property: max-height;
        -moz-transition-property: max-height;
        transition-property: max-height;
        a {
            padding-left: 3rem;
            padding-right: 3rem;
            margin: 0;
        }
        .mat-list-item-content {
            margin-bottom: 0;
        }
    }
    .force-container {
        height: 100%;
    }
    .mat-sidenav-content {
        background-color: $container-bg;
        height: 100%;
        overflow: hidden;
    }
    md-sidenav .sidebar-panel .navigation md-icon:not(.caret) {
        margin-right: 0.75rem;
    }
    .sidebar-panel {
        width: $sidebar-width;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .sidebar-container .mat-nav-list .mat-list-item .mat-list-item-content {
        height: auto;
        display: block;
        margin-bottom: 0.5rem;
        padding: 0;
    }
    .sidebar-container .mat-nav-list .sub-menu .mat-list-item .mat-list-item-content {
        margin-bottom: 0;
    }
    .mat-nav-list .mat-list-item-content:hover,
    .mat-nav-list .mat-list-item-content.mat-list-item-focus,
    .active-link {
        background-color: $mat-list-hover-bg-color;
    }
    .sidebar-panel .material-icons {
        font-size: $sidebar-icons;
        widows: 30px;
    }
    .mat-list-item .menu-caret {
        transition: all 0.3s ease-in-out 0s;
        margin-right: 0 !important;
    }
    .mat-list-item.open .menu-caret {
        transform: rotate(90deg);
    }
    .force-user-thumb {
        padding: 0.9375rem 0;
        img {
            width: 70px;
        }
    }
    //Breadcrumbs
    .force-breadcrumbs {
        font-size: $breadcrumb-font-size;
        color: $breadcrumb-font-color;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .mat-raised-button.mat-primary,
    .mat-fab.mat-primary,
    .mat-mini-fab.mat-primary {
    }
    .mat-mini-fab .mat-icon,
    .mat-mini-fab i {
        padding: 0;
        line-height: 3.3rem;
    }
    .mat-mini-fab i {
        padding: 0.8125rem 0;
    }
    .mat-button {
        font-size: 1rem;
    }
    .mat-icon {
        height: auto;
    }
    .force-header-toolbar .secondary-menu {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
    }
    .secondary-menu button {
        height: 50px;
        margin: 0 0.7rem;
        min-width: 50px;
        width: 50px;
        padding: 0;
    }
    .mat-menu-content {
        .mat-menu-item {
            font-size: 14px !important;
            height: 38px !important;
            line-height: 38px !important;
        }
    }
}

.force-ham-icon {
    .material-icons.mat-icon {
        padding-left: 1.5rem;
    }
}

.app-dark {
    .mat-sidenav-content {
        background: rgba(54, 54, 54, 1);
    }
    .force-breadcrumbs {
        color: mat-color($dark-foreground, base);
    }
    .sidebar-area.mat-sidenav {
        background: mat-color($dark-background, card);
    }
}

.boxed {
    .dash-card {
        .pad-wrap {
            padding-left: 0.2rem;
            padding-right: 0.2rem;
        }
    }
}

.force-base-container {
    height: calc(100% - 64px);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    overflow-anchor: none;
}

.inner-container {
    padding: 1.8rem;
}

.sidebar-container {
    height: 100%;
}

body {
    [dir='rtl'] {
        md-sidenav .sidebar-panel .navigation md-icon:not(.caret) {
            margin-left: 0.75rem;
        }
        .mat-list-item .menu-caret {
            transition: all 0.3s ease-in-out 0s;
            margin-left: 0 !important;
        }
        md-sidenav .sidebar-panel .sub-menu {
            a {
                padding-right: 4rem;
            }
        }
        md-sidenav .sidebar-panel .mat-nav-list a .menu-caret {
            left: 15px !important;
            right: inherit;
        }
        .force-theme-list {
            padding-right: 0;
        }
    }
    .collapsed-sidebar[dir='rtl'] md-sidenav .sidebar-panel .navigation md-icon:not(.caret) {
        margin-left: 0.75rem;
        margin-right: 0;
    }
}

@media (max-width: 1525px) {
    .force-mail-wrapper .mat-list .mat-list-item .mat-list-item-content,
    .force-mail-wrapper .mat-nav-list .mat-list-item .mat-list-item-content {
        height: 30px;
    }
}

@media (max-width: 1199px) {
    .boxed-layout-md {
        display: none;
    }
}

@media (max-width: 991px) {
    .force-breadcrumbs {
        display: none;
    }
    .force-header-toolbar {
        .search-bar,
        .mat-icon-button {
            display: none;
        }
    }
}
