// Color palette
$white: #ffffff;
$grey-text-disable: #b8c7d7;
$grey-text-title: #354251;
$grey-text-description: #8395a8;
$green-avaliable: #289f77;
$grey-text-normal: #56697e;
$blue-tourwow-pro: #155169;
$blue-tourwow: #146d99;
$cyan-tab-table: #19a9c3;
$red-warning: #e12d2d;
$orange-text-note: #ff6704;
$grey-button-disable: #f1f4f9;

$grey-text1: #5e5e5f;
$grey-text-label: #757576;