//General Variables
$sidebar-width: 14rem !default;
$sidebar-text-color: #fff;

//md card
$md-card-border:1px solid rgba(0, 0, 0, 0.1);
$md-card-padding-top-bottom :0.875rem;
$md-card-padding-left-right: 1.5rem;
$md-card-bg-color:#fff;
$md-card-text-color: inherit;
$md-card-title-font-size:1.125rem;
$md-card-title-font-color:inherit;
$md-card-separator-color:rgba(0, 0, 0, 0.7);
$md-card-icon-size:24px;
$md-card-title-bg:inherit;
$container-bg:#f6f8fc;
$highlight-icons: #ffffff;
$highlight-icon-color:#3A405B;
$compact-sidebar-width: 70px !default;
$sidebar-width-mobile: 18rem !default;

//Header
$header-bg-color:#156e9a;

// Typography
//
//Breadcrumb
$breadcrumb-font-size:1rem;
$breadcrumb-font-color:#3A405B;

//Mat list hover bg
$mat-list-hover-bg-color:rgba(0, 0, 0, 0.2);
$html-font-size: 16px;

//form Material
$file-type-area-bg:#eeeeee;
$file-type-dotted-area:#c8c8c8;
$border-radius:8px;

//form wizard
$form-wiz-font-size:1rem;
$form-wiz-tab-bg-color:#eeeeee;
$btn-border:1px solid rgba(0, 0, 0, 0.2);
$active-tab-bg:#5c73db;
$btn-bg-color:#5c73db;
$btn-color:#fff;

//
// Radius
//
$border-radius-base:0px;
