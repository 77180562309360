$green: #5c73db;
$blue: #5c73db;
$red: #5c73db;
$yellow: #5c73db;
$white: #5c73db;
$background-bg:#ffffff;
$width: 60px;

// demo-specific
.loader {
    position: fixed;
    top: 0;
    left: 0;
    background: $background-bg;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100% !important;
}

.showbox {
    width: $width;
    height: $width;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

// end demo-specific
.loader {
    position: relative;
    margin: 0 auto;
    width: $width;
    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
}

.circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {
    100%,
    0% {
        stroke: $red;
    }
    40% {
        stroke: $blue;
    }
    66% {
        stroke: $green;
    }
    80%,
    90% {
        stroke: $yellow;
    }
}