// Text style catalog
@mixin twp-text-XXL-Bold {
    @include twp-text-XXL;
    font-weight: bold;
}

@mixin twp-text-XXL {
    @include twp-text-base;
    font-size: 18px;
    line-height: 1.22;
    color: $grey-text-title;
}

@mixin twp-text-XL-Bold {
    @include twp-text-XL;
    font-weight: bold;
}

@mixin twp-text-XL {
    @include twp-text-base;
    font-size: 16px;
    line-height: 1.13;
    color: $grey-text-title;
}

@mixin twp-text-L-Bold {
    @include twp-text-L;
    font-weight: bold;
}

@mixin twp-text-L {
    @include twp-text-base;
    font-size: 14px;
    color: $grey-text-normal;
}

@mixin twp-text-M-Bold {
    @include twp-text-M;
    font-weight: bold;
}

@mixin twp-text-M {
    @include twp-text-base;
    font-size: 12px;
    line-height: 1.25;
    color: $grey-text-normal;
}

@mixin twp-text-S-Bold {
    @include twp-text-S;
    font-weight: bold;
}

@mixin twp-text-S {
    @include twp-text-base;
    font-size: 10px;
    color: $grey-text-description;
}

@mixin twp-text-XS-Bold {
    @include twp-text-XS;
    font-weight: bold;
}

@mixin twp-text-XS {
    @include twp-text-base;
    font-size: 8px;
    line-height: 1.5;
    color: $grey-text-description;
}

@mixin twp-text-base {
    font-family: Tahoma, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

//Icons
@mixin twp-icon-base {
    background-position: 0px 0px;
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    display: inline-flex;
}

@mixin twp-icon-promotion {
    background-image: url('/assets/img/icon-promotion.png');
}
