
.btn-wrapper .btn {
    min-width: 144px;
}

.btn {
    &.btn--primary {
        background: $blue-tourwow-pro !important;
        border: solid 1px $blue-tourwow-pro;
        color: $white !important;
    }
    
    &.btn--second {
        background: $blue-tourwow !important;
        border: solid 1px $blue-tourwow;
        color: $white !important;
    }
    
    &.btn--grey {
        background-color: #E6E6E6;
        border: solid 1px $blue-tourwow-pro;
        color: $blue-tourwow-pro;
    }
    
    &.btn--orange {
        background-color: #ff5722;
        color: $white;
    }
    
    &.btn--red {
        background-color: #870a0a;
        color: $white;
    }

    &.btn--white {
        background-color: $white;
        border: 1px solid $blue-tourwow-pro;
        color: $blue-tourwow-pro;
    }
    
    &.btn--text {
        background: transparent;
        border: solid 1px transparent;
        color: #5c5c5d;
        text-decoration: underline;
        box-shadow: none;
    }

    &:disabled {
        opacity: 1;
        background-color: #eceef3 !important;
        color:  #8a9bad !important;
        border-color: #d8d9d9 !important;
    }
}

.btn--link {
    cursor: pointer;
    text-decoration: underline !important;
    color: $blue-tourwow !important;
}