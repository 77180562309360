@import "boostrap";

// Text colors
.text- {
    // colors
    &white {
        color: $white !important; //ffffff
    }
    &grey-text-disable {
        color: $grey-text-disable  !important; //b8c7d7
    }
    &grey-text-title {
        color: $grey-text-title  !important; //354251
    }
    &grey-text-description {
        color: $grey-text-description  !important; //8395a8
    }
    &green-avaliable {
        color: $green-avaliable  !important; //289f77
    }
    &grey-text-normal {
        color: $grey-text-normal  !important; //56697e
    }
    &blue-tourwow-pro {
        color: $blue-tourwow-pro  !important; //155169
    }
    &blue-tourwow {
        color: $blue-tourwow  !important; //146d99
    }
    &cyan-tab-table {
        color: $cyan-tab-table  !important; //19a9c3
    }
    &red-warning {
        color: $red-warning  !important; //e12d2d
    }
    &orange-text-note {
        color: $orange-text-note  !important; //ff6704
    }
    &grey-button-disable {
        color: $grey-button-disable  !important; //f1f4f9
    }

    &grey-text1 {
        color: $grey-text1  !important; //5e5e5f
    }
    &grey-text-label {
        color: $grey-text-label  !important; //757576
    }
}

.text- {
    &xxl-bold {
        @include twp-text-XXL-Bold;
    }
    &xxl {
        @include twp-text-XXL;
    }
    &xl-bold {
        @include twp-text-XL-Bold;
    }
    &xl {
        @include twp-text-XL; // 16px
    }
    &l-bold {
        @include twp-text-L-Bold; // 14px
    }
    &l {
        @include twp-text-L; // 14px
    }
    &l {
        @include twp-text-L;
    }
    &m-bold {
        @include twp-text-M-Bold;
    }
    &m {
        @include twp-text-M; // 12px
    }
    &s-bold {
        @include twp-text-S-Bold;
    }
    &s {
        @include twp-text-S; // 10px
    }
    &xs {
        @include twp-text-XS;
    }

    // style
    &link {
        text-decoration: underline;
        cursor: pointer;
    }
}
